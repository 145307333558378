import { useContext, useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReplayIcon from '@mui/icons-material/Replay';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import useAxios from '../../../hooks/useAxios';
import AuthContext from '../../../contexts/auth-context';
import AppContext from '../../../contexts/app-context';
import PatientPortalPageStyle from './PatientPortalPage.module';
import EncounterCard from '../../../components/users/EncounterCard/EncounterCard';
import { urls, PAGE_SIZE, toastify_status, searchByStateButtons } from '../../../components/config/variables'
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import SearchInput from '../../../components/ui/SearchInput';
import {useTranslation} from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress';
import { validateUserEncounters } from '../../../Utils/validateHttpResponse';
import Toastify from '../../../components/ui/Toastify';

const PatientPortalPage = () => {
  const classes = PatientPortalPageStyle();
  const [patientInfo, setPatientInfo] = useState(undefined);
  const [patientEncounters, setPatientEncounters] = useState([]);
  const [patientEncountersError, setPatientEncountersError] = useState(undefined);
  const [patientEncountersCards, setPatientEncountersCards] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [dateValidateError, setDateValidateError] = useState(false);
  const [loadingEcounters, setLoadingEncounters] = useState(true);

  const { operation } = useAxios();
  const authCtx = useContext(AuthContext);
  const appCtx = useContext(AppContext);
  const token = authCtx.token;
  const direction = appCtx.direction

  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const [disaabledSearchButton, setDisabledSearchButton] = useState(true);
  const [searchButtonClicked, setSearchButtonClicked] = useState(false);
  const {t} = useTranslation('doctorProfilePage')
  const [searchByState, setSearchByState] = useState('');

  const SearchByMenuItems = [
    { key: 1, label: t('Search.Date'), value: 'date' },
    { key: 2, label: t('Search.ICDCode'), value: 'icd_code' },
    { key: 3, label: t('Search.CPTCode'), value: 'cpt_code' },
    { key: 4, label: t('Search.ProviderName'), value: 'provider_name' },
    { key: 5, label: t('Search.State'), value: 'state' },
  ];

  useEffect(() => {
    if (typeof appCtx.selectedUser.userProfile !== 'undefined') {
      let filter
      if(searchButtonClicked) {
        if (searchBy === 'date' && fromDate && toDate) {
          filter = `?from_date=${fromDate}&to_date=${toDate}&page=${page}&page_size=${PAGE_SIZE}`;
        } else if (searchBy === 'state') {
          filter = `?state=${searchByState}&page=${page}&page_size=${PAGE_SIZE}`
        } else {
          const selectedSearchBy = searchBy === 'provider_name' ? '?provider=' : searchBy === 'cpt_code' ? '?cpt=' : searchBy === 'state' ? '?state=' : '?icd=';
          const selectedSearchQuery = searchBy === "provider_name" ? searchQuery.toLowerCase() : searchQuery;

          filter = searchQuery
            ? `${selectedSearchBy}${selectedSearchQuery}&page=${page}&page_size=${PAGE_SIZE}`
            : `?page=${page}&page_size=${PAGE_SIZE}`;
        }
        getPatientEncounters(filter);
      } else {
        getPatientEncounters(`?page=${page}&page_size=${PAGE_SIZE}`);
      }

      setPatientInfo(appCtx.selectedUser);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(appCtx.selectedUser), page, searchButtonClicked]);

  const getPatientEncounters = (filter) => {
    setLoadingEncounters(true)
    operation(
      {
        method: "GET",
        //url comes from the context to seperate patient profile
        url: urls.GET_USER_ENCOUNTERS + filter,
        headers: { Authorization: `token ${token}` },
        data: {},
      },
      sortFilteredPatientEncounters,
      setPatientEncountersError
    );
  }

  const handleChangeSearchBy = (event) => {
   setSearchBy(event.target.value);
   setDateValidateError(false);
   setSearchQuery('');
   setFromDate('')
   setToDate('')
   setSearchByState('')
   setSearchButtonClicked(false)
   setPage(1)
  };

  const reformateData = (encounters) => {
    return encounters.map((patientEncounter) => {
        return <EncounterCard key={patientEncounter.id} encounter={patientEncounter} />;
    });
  };

  const handleChangeSearch = (e) => {
    const {value, name} = e.target

    if (searchBy === 'date') {
      const currentDate = new Date();
      const date = new Date(value);

      if (date < currentDate) {

        if(name === 'fromDate'){
          setFromDate(value);
        } else if (name === 'toDate'){
          setToDate(value);
        }
        setDateValidateError(false);
      } else {
        setDateValidateError(true);
      }

    } else {
      setSearchQuery(value);
    }
  };

   // set date validate to error if from date bigger than to date
   useEffect(() => {
    if(toDate){
      if(fromDate > toDate){
        setDateValidateError(true);
      } else {
        setDateValidateError(false);
      }
    } 
  }, [fromDate, toDate])

   // set disabled search button to true if there is date validate error of there is no search fromDate or searchQuery
   useEffect(() => {
    const isValidDate = fromDate && toDate && !dateValidateError;
    const isValidSearch = searchQuery && !dateValidateError;

    if (isValidDate || isValidSearch) {
      setDisabledSearchButton(false);
    } else {
      setDisabledSearchButton(true);
    }
  }, [fromDate, toDate, searchQuery, dateValidateError]);

  const handleSearch = (state) => {
    setPage(1);
    setSearchButtonClicked(true);
    if (searchBy === 'date') {
      getPatientEncounters(`?from_date=${fromDate}&to_date=${toDate ? toDate : fromDate}&page=1&page_size=${PAGE_SIZE}`);
    } else if (searchBy === 'cpt_code') {
      getPatientEncounters(`?cpt=${searchQuery}&page=1&page_size=${PAGE_SIZE}`);
    } else if (searchBy === 'icd_code') {
      getPatientEncounters(`?icd=${searchQuery}&page=1&page_size=${PAGE_SIZE}`);
    } else if (searchBy === 'state') {
      getPatientEncounters(`?state=${state}&page=1&page_size=${PAGE_SIZE}`);
    } else if (searchBy === 'provider_name') {
      getPatientEncounters(`?provider=${searchQuery}&page=1&page_size=${PAGE_SIZE}`);
    } else {
      console.log ("Unknown filter")
    }
  };

  const defaultSearch = () => {
    setSearchButtonClicked(false);
    if(page !== 1) {
      setPage(1);
    } else {
      getPatientEncounters(`?page=${page}&page_size=${PAGE_SIZE}`)
    }
  }

  const handleClearSearch = () => {
      setSearchQuery('');
      setSearchBy('')
      setFromDate('')
      defaultSearch()
  };

  const handleClearDate = (value) => {
    if(value === 'fromDate'){
      setFromDate('')
    } else if (value === 'toDate'){
      setToDate('')
    }
    setDateValidateError(false);
    
    defaultSearch()
  }

  // call handle clear search and clear to date to reload patient encounters and clear any search
  const reloadPatientEncounters = () => {
    handleClearSearch()
    setToDate('')
  }

  const sortByDate = (array) => {
    return array.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA;
    });
  };

  const sortFilteredPatientEncounters = (encounters) => {
    if(validateUserEncounters(encounters)) {
      setLoadingEncounters(false)
      const sortedEncounters = sortByDate(encounters.results);
      setPatientEncounters(sortedEncounters);
      setPatientEncountersCards(reformateData(sortedEncounters));
      setCount(Math.ceil(encounters.count / PAGE_SIZE));
    } else {
      console.log('invalid user encounters response')
      Toastify({message: 'invalid user encounters response', state: toastify_status.ERROR})
    }
  };

  const reloadEncounter = () => {
      return (
        <IconButton size="small" sx={classes.arrow_button} onClick={reloadPatientEncounters}>
          <ReplayIcon fontSize="small" sx={classes.arrow_icon} />
        </IconButton>
      )
  }

 // this function called when select search by encounter state and click state button
  const handleSelectEncounterState = (btn) => {
    if(btn.name === searchByState) {
      setSearchByState('')
      defaultSearch()
    } else {
      handleSearch(btn.name)
      setSearchByState(btn.name)
    }
  }

  return !patientInfo ? (
    // <Alert severity="error">
    // <Alert>
    //   <AlertTitle>Patient Not Found</AlertTitle>
    // </Alert>
    <CircularProgress sx={{ marginLeft: '50%', transform: 'translate(-50%, -50%)' }}/>
  ) : (
    <Box>
      {/* {arrowBack()} */}
      <Box sx={classes.container}>
        <Box sx={classes.user_info_container} dir={direction}>
          <Box sx={classes.avatar_container}>
            <Avatar
              alt="user1"
              src={patientInfo.userProfile.user?.gender === 'F' ? '/images/patient_woman.svg' : '/images/patient_man.svg'}
              sx={classes.user_avatar}
            />
            <Typography sx={classes.user_name}>{patientInfo.userProfile.user?.name}</Typography>
          </Box>
          <Box sx={classes.user_details}>
            <Box sx={classes.user_info_box}>
              <Typography>
               {t('GovernmentID')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo.userProfile.user?.eid}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box}>
              <Typography>
               {t('Nationality')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo.userProfile.user?.nationality}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box}>
              <Typography>
                {t('DateOfBirth')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo.userProfile.user?.birthdate}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box}>
              <Typography>
                {t('Age')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo.userProfile.user?.age} Y
                </Typography>
            </Box>
            <Box sx={classes.user_info_box}>
              <Typography>
               {t('Gender')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo.userProfile.user?.gender === 'F' ? 'Female' : 'Male'}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box}>
              <Typography>
                {t('Employer')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo.userProfile.user?.employer}
                </Typography>
            </Box>

            <Box sx={classes.user_info_box}>
              <Typography>
               {t('PrimaryInsurance')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo?.userProfile.primary_insurance_name}
                </Typography>
            </Box>
            
            <Box sx={classes.user_info_box}>
              <Typography>
               {t('PrimaryInsuranceID')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo?.userProfile.patient_primary_insurance_id}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box}>
              <Typography>
                {t('MobileNumber')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo.userProfile.user?.phone_number}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box_extended}>
              <Typography>
               {t('EmailAddress')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo.userProfile.user?.email}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box_extended}>
              <Typography>
                {t('PhysicalAddress')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {patientInfo.userProfile.user?.physical_address}
                </Typography>
            </Box>
          </Box>
        </Box>
        {patientEncountersError ? (
          <Box dir={direction} sx={classes.alertBox}>
            <Alert severity="error">
              <AlertTitle sx={classes.alertTitle}>{t('PatientEncountersNotFoundTitle')}</AlertTitle>
              {t('PatientEncountersNotFoundMessage', {patientId: ""})}<br />
            </Alert>
          </Box>
        ) : (
          <Box sx={classes.user_encounter_container}>
            <Box sx={{display:"flex", flexDirection:"row", justifyContent: "space-between"}}  dir={direction}>
            <Typography sx={classes.sub_title}>{t('EncountersHistory')}</Typography>
            {reloadEncounter()}
            </Box>

            <Box sx={classes.search_container}>
              <FormControl fullWidth sx={classes.search_by} size="small">
                <InputLabel>{t('Search.SearchBy')}</InputLabel>
                <Select
                  label={t('Search.SearchBy')}
                  value={searchBy}
                  onChange={handleChangeSearchBy}
                  disabled={!patientEncounters || patientEncounters.length === 0}
                >
                  {SearchByMenuItems.map((item) => (
                    <MenuItem key={item.key} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {searchBy === 'date' ? (
              <>
               <SearchInput
               handleSearch={handleSearch}
               type={'date'}
               value={fromDate}
               name='fromDate'
               handleChangeSearch={handleChangeSearch}
               dateValidateError={dateValidateError}
               label={t('Search.FromDate')}
               handleClearDate={() => handleClearDate('fromDate')}
             />
              <SearchInput
               handleSearch={handleSearch}
               type={'date'}
               value={toDate}
               name='toDate'
               setTodayToDate={setToDate}
               handleChangeSearch={handleChangeSearch}
               dateValidateError={dateValidateError}
               label={t('Search.ToDate')}
               handleClearDate={() => handleClearDate('toDate')}
             />
            </>

            ) : searchBy === 'state' ? (
              <Box sx={classes.searchByStateBox}>
                {searchByStateButtons.map((btn, i) => {
                  return <Button
                    key={i}
                    sx={classes.searchByStateButtons}
                    name={btn.name}
                    variant={
                      searchByState === btn.name ? 'contained' : 'outlined'
                    }
                    color={btn.color}
                    onClick={() => handleSelectEncounterState(btn)}
                  >
                    {btn.name}
                  </Button>
                })}
              </Box>
            ) : (
              <SearchInput
              handleSearch={handleSearch}
              type={'text'}
              value={searchQuery}
              handleChangeSearch={handleChangeSearch}
              dateValidateError={dateValidateError}
              searchBy={searchBy}
              searchQuery={searchQuery}
              handleClearSearch={handleClearSearch}
            />
            )}
              
              {searchBy !== 'state' && (
                    <Button disabled={disaabledSearchButton} variant="contained" onClick={handleSearch} sx={classes.search_button}>
                      {t("Search.Search")}
                    </Button>
                  )}
            </Box>

          {!loadingEcounters && patientEncountersCards.length > 0 ?
              patientEncountersCards :
              loadingEcounters ?
                <Box sx={classes.loadEncounters}>
                  <CircularProgress/>
                </Box>:
                <Box sx={classes.not_found_message}>
                  <SentimentDissatisfiedIcon />
                  <Typography>{t("PatientEncountersNotFoundTitle")}</Typography>
                </Box>
            }

                {!loadingEcounters && count > 1 && <Box sx={classes.pagination}>
                  <Stack spacing={2} sx={classes.stack}>
                    <Pagination page={page} disabled={count === 1} count={count} variant="outlined" color="primary" size="large" onChange={(e, v) => setPage(v)} />
                  </Stack>
                </Box>}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PatientPortalPage;
