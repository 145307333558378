const classes = {
  container:{ 
    display: 'flex', 
    flexDirection: 'row', 
    minHeight: "100vh",
    // alignItems: 'start', 
    gap: '32px',
  },
  sidebar_container: {
    flex: 1,
    display: { xs: "none", md: "contents" },
  },
  navbar_container: {
    flex: 1,
    width: '100%',
  },
  outlet_container: {
    marginRight: '32px',
    marginLeft: {xs:'32px', md: '0'},
    marginTop: '32px',
  },
  drawer: {
    display: { xs: "flex", md: "none" }
  }
 
};

export default classes