import { useCallback, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import NewEncounterFormStyle from './NewEncounterForm.module';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import Tooltip from '@mui/material/Tooltip';
import Select from '@mui/material/Select';
import DrugName from './DrugName';
import { FormControl } from '@mui/material';
import { setInputOptionsAndValue } from './formUtils';
import {useTranslation} from 'react-i18next'

const TreatmentForm = ({
  treatmentInput,
  handleTreatmentInputValue,
  treatmentInputsValue,
  handleRemoveTreatmentForm,
  clearSearch,
  handleDeleteDrugValue,
  isDisabled,
  enounterError,
  diagnoses,
  favourite,
  updateFavourite
}) => {
  const classes = NewEncounterFormStyle();
  const { index, label } = treatmentInput;
  // const [medicationDoseOptionss, setMedicationDoseOptions] = useState([]);
  const [dosageFormOptions, setDosageFormOptions] = useState([]);
  const [medicationDosError, setMedicationDosError] = useState([]);
  const [frequencyError, setFrequencyError] = useState([]);
  const [durationError, setDurationError] = useState([]);
  const [claimedQuantityError, setClaimedQuantityError] = useState([]);
  const [costError, setCostError] = useState([]);
  const {t} = useTranslation('newEncounter')
  // this is the drug product id cames from DrugName file
  const [product_id, setproduct_id] = useState('');

  const removeErrors = () => {
    setMedicationDosError([])
    setFrequencyError([])
    setDurationError([])
    setClaimedQuantityError([])
    setCostError([])
  }

  // check each drug  field error
  useEffect(() => {
    if(enounterError) {
      removeErrors()

      enounterError.errors.map((error) => {
        const errorIndex = error.attr?.split('.')[1]
        const field = error.attr?.split('.')[2]

        switch(field) {
          case 'drug_dose':
            return setMedicationDosError((prev) => [...prev, { errorIndex, field }])
          case 'frequency':
            return setFrequencyError((prev) => [...prev, { errorIndex, field }])
          case 'duration':
            return setDurationError((prev) => [...prev, { errorIndex, field }])
          case 'claimed_quantity':
            return setClaimedQuantityError((prev) => [...prev, { errorIndex, field }])
          case 'cost':
            return setCostError((prev) => [...prev, { errorIndex, field }])
          default:
            return null
        }
      })
    }
  }, [enounterError])


  //const [unitPricetoPublicOptions, setUnitPricetoPublicOptions] = useState([]);

  const getSelectedOptionObject = useCallback((selectedOptionObject) => {
    // this field now fill from the client side
    // setInputOptionsAndValue(
    //   selectedOptionObject ? selectedOptionObject['med_strength'] : [],
    //   'drug_dose',
    //   setMedicationDoseOptions,
    //   handleTreatmentInputValue,
    //   index
    // );
    setInputOptionsAndValue(
      selectedOptionObject ? selectedOptionObject['product_package_desc'] : [],
      'dosage_form',
      setDosageFormOptions,
      handleTreatmentInputValue,
      index
    );
    // this field is removed
    // setInputOptionsAndValue(
    //   selectedOptionObject ? selectedOptionObject['product_package_unit'] : [],
    //   'admin_route',
    //   setAdminRouteOptions,
    //   handleTreatmentInputValue,
    //   index
    // );
    setInputOptionsAndValue(
      selectedOptionObject ? selectedOptionObject['unitpricetopublic'] : [],
      'unitpricetopublic',
      //setUnitPricetoPublicOptions,
      () => {},
      handleTreatmentInputValue,
      index
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // calculate drug cost depend on duration and frequency
  const calculateCost = (index) => {
    try {
      const duration = parseInt(treatmentInputsValue[index].duration);
      const frequency = parseInt(treatmentInputsValue[index].frequency);
      const unitpricetopublic = parseFloat(treatmentInputsValue[index].unitpricetopublic)
      const cost = duration * frequency * unitpricetopublic
      const costInDecimal = Number(cost.toFixed(2))
      treatmentInputsValue[index].cost = costInDecimal
      return costInDecimal
    }
    catch(err) {
      console.log(err)
    }
  }

  useEffect(() => {
    setDosageFormOptions([]);
    // setMedicationDoseOptions([]);
    //setUnitPricetoPublicOptions([]);
  }, [clearSearch]);

  const setProductId = (id) => {
    setproduct_id(id)
  }

  useEffect(() => {
    removeErrors()
  }, [product_id])

  return (
    <Box>
      <Box sx={classes.input_label_container}>
        <Typography sx={classes.input_label}>{label}</Typography>
        {!(index === 0) && (
          <IconButton size="small" onClick={() => handleRemoveTreatmentForm(index)}>
            <Tooltip title="Remove Input">
              <RemoveIcon sx={classes.remove_icon} />
            </Tooltip>
          </IconButton>
        )}
      </Box>
      <Box sx={classes.treatment_inputs_container}>
        <Box sx={{ flexBasis: '99%', marginTop: '8px' }}>
          <DrugName
            isDisabled={isDisabled}
            index={index}
            product_id={treatmentInputsValue[index].product_id} 
            handleSelectDrug={handleTreatmentInputValue}
            clearSearch={clearSearch}
            handleDeleteDrugValue={handleDeleteDrugValue}
            getSelectedOptionObject={getSelectedOptionObject}
            setProductId={setProductId}
            diagnoses={diagnoses}
            favourite={favourite}
            updateFavourite={updateFavourite}
          />
        </Box>
        <Box sx={classes.treatment_input_control}>
          <InputLabel disabled={isDisabled} sx={classes.input_label}>
            {t("DosageForm")}
          </InputLabel>
          <FormControl disabled={dosageFormOptions.length === 0 || isDisabled} fullWidth size="small">
            {!treatmentInputsValue[index].dosage_form && (
              <InputLabel shrink={false}>{dosageFormOptions.length === 0 ? 'Select medication name first' : 'Select Option'}</InputLabel>
            )}
            <Select
              onChange={(event) => handleTreatmentInputValue(event, index)}
              value={treatmentInputsValue[index].dosage_form}
              name="dosage_form"
              MenuProps={{ style: classes.menu_props }}
            >
              {dosageFormOptions}
            </Select>
          </FormControl>
        </Box>

        {/* <Box sx={classes.treatment_input_control}>
          <InputLabel disabled={isDisabled} sx={classes.input_label}>
            {t("MedicationDose")}
          </InputLabel>
          <FormControl disabled={medicationDoseOptions.length === 0 || isDisabled} fullWidth size="small">
            {!treatmentInputsValue[index].drug_dose && (
              <InputLabel shrink={false}>
                {medicationDoseOptions.length === 0 ? 'Select medication name first' : 'Select Option'}
              </InputLabel>
            )}
            <Select
              onChange={(event) => handleTreatmentInputValue(event, index)}
              value={treatmentInputsValue[index].drug_dose}
              name="drug_dose"
              MenuProps={{ style: classes.menu_props }}
            >
              {medicationDoseOptions}
            </Select>
          </FormControl>
        </Box> */}

        <Box sx={classes.treatment_input_control}>
          <InputLabel disabled={isDisabled} sx={[classes.input_label, {color: medicationDosError.map((error) => Number(error.errorIndex) === index && 'red') }]}>
            {t("MedicationDose")}
          </InputLabel>
          <TextField
            variant="outlined"
            type="number"
            InputProps={{ inputProps: { min: 0, max: 100 } }}
            fullWidth
            size="small"
            onChange={(event) => handleTreatmentInputValue(event, index)}
            value={treatmentInputsValue[index].drug_dose}
            name="drug_dose"
            disabled={isDisabled}
          />
        </Box>
        <Box sx={classes.treatment_input_control}>
          <InputLabel disabled={isDisabled} sx={[classes.input_label, {color: frequencyError.map((error) => Number(error.errorIndex) === index && 'red') }]}>
             {t("DailyFrequency")}
          </InputLabel>
          <TextField
            variant="outlined"
            type="number"
            InputProps={{ inputProps: { min: 0, max: 100 } }}
            fullWidth
            size="small"
            onChange={(event) => handleTreatmentInputValue(event, index)}
            value={treatmentInputsValue[index].frequency}
            name="frequency"
            disabled={isDisabled}
          />
        </Box>
        <Box sx={classes.treatment_input_control}>
          <InputLabel disabled={isDisabled} sx={[classes.input_label, {color: durationError.map((error) => Number(error.errorIndex) === index && 'red') }]}>
             {t("DurationInDays")}
          </InputLabel>
          <TextField
            variant="outlined"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            fullWidth
            size="small"
            onChange={(event) => handleTreatmentInputValue(event, index)}
            value={treatmentInputsValue[index].duration}
            name="duration"
            disabled={isDisabled}
          />
        </Box>
        <Box sx={classes.treatment_input_control}>
          <InputLabel disabled={isDisabled} sx={[classes.input_label, {color: claimedQuantityError.map((error) => Number(error.errorIndex) === index && 'red') }]}>
            {t("ClaimedQuantity")}
          </InputLabel>
          <TextField
            variant="outlined"
            type="number"
            InputProps={{ inputProps: { min: 0, max: 100 } }}
            fullWidth
            size="small"
            onChange={(event) => handleTreatmentInputValue(event, index)}
            value={treatmentInputsValue[index].claimed_quantity}
            name="claimed_quantity"
            disabled={isDisabled}
          />
        </Box>
        <Box sx={classes.treatment_input_control}>
          <InputLabel disabled={isDisabled} sx={[classes.input_label, {color: costError.map((error) => Number(error.errorIndex) === index && 'red') }]}>
             {t("Cost")}
          </InputLabel>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            //onChange={(event) => handleTreatmentInputValue(event, index)}
            //value={treatmentInputsValue[index].frequency && treatmentInputsValue[index].duration? calcost: 0}
            value={calculateCost(index) || 0}
            name="cost"
            disabled={true}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TreatmentForm;
