import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import NewEncounterFormStyle from './NewEncounterForm.module';
import useAxios from '../../../hooks/useAxios';
import AuthContext from '../../../contexts/auth-context';
import { useContext, useState, useMemo, useEffect, memo  } from 'react';
import { urls, MIN_NUMBER_OF_SEARCH, toastify_status } from '../../config/variables';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { validateIcdCode } from '../../../Utils/validateHttpResponse';
import Toastify from '../../ui/Toastify';
import MedicalSelections from './MedicalSelections';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

const DiagnoseSelect = memo(
  ({ diagnoseInput, handleSelectDiagnose, diagnoses, clearSearch, handleDeleteDiagnose, handleRemoveDiagnoseInput, isDisabled, favourite, updateFavourite }) => {
    const { label, index, name } = diagnoseInput;
    const classes = NewEncounterFormStyle();
    const { operation } = useAxios();
    const authCtx = useContext(AuthContext);
    const [diagnoseOptions, setDiagnoseOptions] = useState([]);
    const [diagnoseOptionsError, setDiagnoseOptionsError] = useState(undefined);
    const [searchQuery, setSearchQuery] = useState('');
    const [prevSearchQuery, setPrevSearchQuery] = useState('');
    const [selectInputLabel, setSelectInputLabel] = useState('Please search first before selecting.');
    // favourite diagnoses
    const [favouriteDiagnoses, setFavouriteDiagnoses] = useState([])
    const [openFavouriteDiagnosesDialog, setOpenFavouriteDiagnosesDialog] = useState(false);
    const [isFavourite, setIsFavourite] = useState(false);
    // check if the selected diagnoses is a favourite diagnose or no
    const [selectedFavouriteDiagnose, setsSelectedFavouriteDiagnose] = useState(false);
    const [loadingFavourite, setLoadingFavourite] = useState(false);

    useEffect(() => {
      if(Array.isArray(favourite.icds)) {
        setFavouriteDiagnoses(favourite.icds)
        setLoadingFavourite(false)
      }
    }, [favourite])

    const handleSetDiagnoseOptions = (resDiagnoseOptions) => {
      handleDeleteSelectedDiagnose()
      if(validateIcdCode(resDiagnoseOptions)) {
        setDiagnoseOptions(resDiagnoseOptions);
        resDiagnoseOptions.length > 0
          ? setSelectInputLabel(<Typography sx={classes.found_options}>Select an option..</Typography>)
          : setSelectInputLabel(<Typography sx={classes.not_found_options}>No Diagnosis found for "{searchQuery}".</Typography>);
      } else {
        console.log('invalid diagnosis response')
        Toastify({message: 'invalid diagnosis response', state: toastify_status.ERROR})
      }
    };

    const handleSubmit = () => {
      if (searchQuery.length >= MIN_NUMBER_OF_SEARCH) {
        setDiagnoseOptionsError('');
        operation(
          {
            method: 'POST',
            url: urls.LIST_SEARCHED_DIAGNOSES,
            headers: { Authorization: `token ${authCtx.token}` },
            data: {"searchfor": searchQuery},
          },
          handleSetDiagnoseOptions,
          setDiagnoseOptionsError
        );
      }
    };

    const handleSearch = (event) => {
      const value = event.target.value;
      if (typeof value !== 'undefined') {
        setSearchQuery(value);
      }
    };

    // Note: this clearSearchQuery argument is a simple string passed over from handleClearDiagnose InputAdornment below
    const handleClearDiagnose = (clearSearchQuery) => {
      if(clearSearchQuery) {
        setSearchQuery('');
      }
      handleDeleteDiagnose(index);
      setDiagnoseOptions([]);
      setDiagnoseOptionsError(undefined);
      setSelectInputLabel('Please search first before selecting.');
      setIsFavourite(false)
      setsSelectedFavouriteDiagnose(false)
    }

    const diagnosesMenuItems = useMemo(() => {
      const options = isFavourite ? favouriteDiagnoses : diagnoseOptions
      return options.map((diagnose) => (
        <MenuItem key={diagnose.icdcode} value={diagnose.icdcode}>
          ICD ({diagnose.icdcode}): {diagnose.shortdescription}
        </MenuItem>
      ));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [diagnoseOptions, isFavourite]);

    useEffect(() => {
      setSearchQuery('');
      setDiagnoseOptions([]);
      setDiagnoseOptionsError(undefined);
      setSelectInputLabel('Please search first before selecting.');
    }, [clearSearch]);

    useEffect(() => {
      setPrevSearchQuery(searchQuery)
    }, [searchQuery])

    /* use effect to clear Diagnoses in case the user removed or changed the search query
     after get diagnosee options or select diagnose */
     useEffect(() => {
      if (
        (diagnoses[index] && 
         (!searchQuery || (prevSearchQuery.length >= 3 && searchQuery.length === 1))) || 
        (diagnoseOptions.length > 0 && !searchQuery)
      ) {
        handleClearDiagnose();
      }
    // eslint-disable-next-line
    }, [diagnoses, searchQuery, prevSearchQuery, diagnoseOptions]);

    // delete selected Diagnose after Re-search for options
    const handleDeleteSelectedDiagnose = () => {
      if(diagnoses[index]) {
        handleDeleteDiagnose(index);
      }
    }

    const handleOpenFavouriteDiagnosesPopup = () => {
      setOpenFavouriteDiagnosesDialog(true);
    };

    const handleCloseFavouriteDiagnosesPopup = () => {
      setOpenFavouriteDiagnosesDialog(false);
    };

    const selectFavouriteDiagnose = (icd) => {
      const event ={target: {value: icd.icdcode, name: index === 0 ? 'primary': index === 1 ? 'secondary' : 'Diagnosis' + index}}
      handleSelectDiagnose(event, index)
      setIsFavourite(true)
      setOpenFavouriteDiagnosesDialog(false)
      setSearchQuery(icd.shortdescription)
      setDiagnoseOptions([{...icd}])
    }

    // check if the selected diagnoses is a favourite diagnose or no
    useEffect(() => {
      if (diagnoses[index] && diagnoses[index].icd) {
        setsSelectedFavouriteDiagnose(favouriteDiagnoses.find((icd) => icd.icdcode === diagnoses[index].icd));
      }
    }, [diagnoses, index, favouriteDiagnoses]);

    const addFavouriteDiagnose = () => {
      const favouriteDiagnosesList = favouriteDiagnoses.map((icd) => icd.icdcode)
      const updatesDiagnosesList = [...favouriteDiagnosesList, diagnoses[index].icd]
      const data = {'icds': updatesDiagnosesList}
      updateFavourite(data)
      setLoadingFavourite(true)
    }

    const RemoveFavouriteDiagnose = () => {
      const updatesDiagnosesList = favouriteDiagnoses.map((icd) => icd.icdcode).filter((icd) => icd !== diagnoses[index].icd)
      const data = {'icds': updatesDiagnosesList}
      updateFavourite(data)
      setLoadingFavourite(true)
    }

    return (
      <Box>
        <Box sx={classes.input_label_container}>
          <InputLabel sx={classes.input_label}>{label}</InputLabel>
          {!(index === 0 || index === 1) && (
            <IconButton size="small" onClick={() => handleRemoveDiagnoseInput(index)}>
              <Tooltip title="Remove Input">
                <RemoveIcon sx={classes.remove_icon} />
              </Tooltip>
            </IconButton>
          )}

          <Box sx={classes.input_label_container_buttons_box}>
              <Button
                sx={classes.open_dialog_button}
                onClick={handleOpenFavouriteDiagnosesPopup}
                disabled={isDisabled || (!loadingFavourite && favouriteDiagnoses.length === 0)}
              >
                Favorite Diagnoses
                {loadingFavourite && (
                  <CircularProgress
                    size={14}
                    sx={classes.input_label_container_button_circular_progress}
                  />
                )}
              </Button>
            </Box>
        </Box>


        <Box sx={classes.select_inputs_container}>
          <TextField
            disabled={isDisabled}
            variant="outlined"
            InputProps={{
              startAdornment: searchQuery && (
                <InputAdornment position="start">
                  <IconButton onClick={() => handleClearDiagnose('clearSearchQuery')} sx={classes.clear_button}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSubmit} sx={classes.search_button} disabled={searchQuery.length < MIN_NUMBER_OF_SEARCH}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={searchQuery}
            onChange={handleSearch}
            placeholder={`Search for at least ${MIN_NUMBER_OF_SEARCH} characters`}
            sx={classes.search_input}
            size="small"
            error={!!diagnoseOptionsError}
            helperText={diagnoseOptionsError ? diagnoseOptionsError.message : ''}
          />
          <FormControl sx={classes.select_input} size="small" disabled={isDisabled || (diagnoseOptions.length === 0 && isFavourite === false)}>
            {!diagnoses[index]?.icd && <InputLabel shrink={false}>{selectInputLabel}</InputLabel>}
            <Select
              name={name}
              value={diagnoses[index]?.icd || ''}
              onChange={(event) => handleSelectDiagnose(event, index)}
              MenuProps={{ style: classes.menu_props }}
              onOpen={() => {
                setIsFavourite(false)
              }}
            >
              {diagnosesMenuItems}
            </Select>
          </FormControl>

          <Box sx={classes.star_box}>
            {loadingFavourite ? (
              <CircularProgress size={20} />
            ) : (
              <IconButton
                disabled={!diagnoses[index]}
                onClick={selectedFavouriteDiagnose ? RemoveFavouriteDiagnose : addFavouriteDiagnose}
              >
                <Tooltip title={selectedFavouriteDiagnose ? 'Remove From Favourites' : 'Add To Favourites'}>
                  {selectedFavouriteDiagnose ? <StarIcon /> : <StarBorderIcon />}
                </Tooltip>
              </IconButton>
            )}
        </Box>

        </Box>
        <MedicalSelections
         selectionType='icd'
         title='Favourite Diagnose'
         open={openFavouriteDiagnosesDialog}
         handleClose={handleCloseFavouriteDiagnosesPopup}
         loading={false}
         data={favouriteDiagnoses}
         selectOption={selectFavouriteDiagnose}
          />
      </Box>
    );
  }
);

export default DiagnoseSelect;
