import { useTheme } from '@emotion/react';

const SideBarStyle = () => {
  const theme = useTheme();
  const navLinkStyles = ({ isActive }) => {
    return {
      textDecoration: 'none',
      textTransform: 'capitalize',
      color: '#000',
      backgroundColor: isActive ? theme.palette.primary['20'] : '',
      display: 'block',
      padding: isActive ? '10px 16px' : '10px 24px',
      borderLeft: isActive ? '8px solid' : '',
      borderLeftColor: isActive ? theme.palette.primary.main : '',
    };
  };
  return {
    link: navLinkStyles,
    patient_card: {
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
    },
    patient_card_username: {
      marginTop: '8px',
    },
    container: {
      background: theme.palette.primary['10'],
      width: { xs: '100vw', sm: '55vw', md: '32%', lg: '23%', xl: '22%' },
      overflow: 'auto',
    },
    side_controls: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 24px',
      height: '83px',
    },
    logo: {
      height: '40px',
    },
    close_button: {
      display: { xs: 'contents', md: 'none' },
    },
    side_bar_contents: {
      padding: '0 24px',
    },
    search: {
      marginTop: '21px',
      background: 'white',
    },
    side_bar_title: {
      fontSize: '16px',
      marginTop: '24px',
      marginBottom: '24px',
    },
    patients_container: {
      minHeight: '100vh',
      overflow: 'auto',
      // height: { xs: "calc(100vh - 380px)", md: "calc(100vh - 310px)" },
      marginBottom: '32px',
      width: '100%',
    },
    see_more: {
      marginTop: '28px',
      textDecoration: 'underline',
      ontSize: '12px',
      cursor: 'pointer',
      padding: '0 24px',
    },
    see_more_icon: {
      height: '10px',
    },
    divider: {
      marginBottom: '50px',
    },
    profile_logout: {
      display: { xs: 'flex', md: 'none' },
      flexDirection: 'row-reverse',
      gap: '24px',
      padding: '0 40px',
      justifyContent: 'space-between',
    },
    logout_button: {
      width: '100%',
    },
    profile_avatar: {
      width: '60px',
      height: '60px',
      border: '1.5px solid',
      borderColor: theme.palette.primary.main,
    },
    pagination: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '24px',
    },
    search_button: {
      backgroundColor: theme.palette.primary.main,
      padding: '4px',
      color: '#fff',
      '&: hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    usersListCircularProgress: {
      display: 'flex',
      justifyContent: 'center'
    }
  };
};

export default SideBarStyle;
