import { useContext, useState, useEffect } from 'react';
import AppContext from '../../../contexts/app-context';
import Box from '@mui/material/Box';
import LanguageIcon from '@mui/icons-material/Language';
import MenuItem from '@mui/material/MenuItem';
import CircularProgressComponent from '../CircularProgressComponent';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';

const LanguageSwitcher = ({ customStyle }) => {
  const [loading, setLoading] = useState(false);
  const appCtx = useContext(AppContext);
  const language = appCtx.language;

  const handleChangeLanguage = (lang) => {
    setLoading(true);
    appCtx.changeLanguage(lang);
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }, [loading]);

  return (
    <>
      {loading && (
        <CircularProgressComponent />
      )}

    <Box sx={customStyle}>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <LanguageIcon sx={{marginRight: '5px'}}/>
        {language === 'en' ? 'EN' : 'AR'}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleChangeLanguage('en')}>English</MenuItem>
        <MenuItem onClick={() => handleChangeLanguage('ar')}>العربية</MenuItem>
      </Menu>
    </Box>
    </>
  );
};

export default LanguageSwitcher;
