import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../contexts/app-context';
import AuthContext from '../../../contexts/auth-context';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EncounterCardStyle from './EncounterCard.module';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import 'reactjs-popup/dist/index.css';
import useAxios from '../../../hooks/useAxios';
import { urls, app_paths, ENCOUNTER_ACTIONMSG, reload_encounter, toastify_status } from '../../config/variables';
import CircularProgress from '@mui/material/CircularProgress';
import CachedIcon from '@mui/icons-material/Cached';
import RenderDrugActionMessages from './RenderDrugActionMessages';
import {useTranslation} from 'react-i18next'
import RenderIcdCptActionMessages from './RenderIcdCptActionMessages';
import { useNavigate, useLocation } from 'react-router-dom';
import { validateUserResponse, validateAnEncounter } from '../../../Utils/validateHttpResponse';
import Toastify from '../../ui/Toastify';

const EncounterCard = (encounter) => {
  const classes = EncounterCardStyle();
  const appCtx = useContext(AppContext);
  const authCtx = useContext(AuthContext);
  const userRole = appCtx.userRole;
  const token = authCtx.token;
  const [encounterObj, setEncounterObj] = useState(encounter.encounter);
  const [loadingEcounter, setLoadingEncounter] = useState(false);
  const [keepFetchingEncounter, setKeepFetchingEncounter] = useState(true);
  const { operation } = useAxios();
  const {t} = useTranslation('encounterCard')
  const direction = appCtx.direction
  const navigate = useNavigate();
  const location = useLocation();
  const disablePatientName = userRole === 'patient' || userRole === 'pharmacy_admin' || location.pathname.includes('/patient')

  // this use effect will run if the user reload or navigate to next page
  useEffect(() => {
    setEncounterObj(encounter.encounter)
  }, [encounter])

  const reFetchEncounter = () => {
    setLoadingEncounter(true);
    operation(
      {
        method: 'GET',
        url: `${urls.GET_ENCOUNTER}/${encounterObj.id}`,
        headers: { Authorization: `token ${token}` },
      },
      reloadedEncounterData,
      reloadedEncounterError
    );
  };

  const reloadedEncounterData = (newEncounterObj) => {
    setLoadingEncounter(false);
    if(validateAnEncounter(newEncounterObj)) {
      setEncounterObj(newEncounterObj);
    } else {
      console.log('invalid reloaded encounter response')
      Toastify({message: 'invalid reloaded encounter response', state: toastify_status.ERROR})
    }
    
  };

  const reloadedEncounterError = (error) => {
    setLoadingEncounter(false);
    console.error('Encounter fetch failed:', error);
  };

  // this function set icd, cpt, drug color depend on encounter action message or subo action id
  const suboColor = (subo) => {
    return subo.actionid.length > 0
      ? classes.encounter_info_error
      : classes.encounter_info
  }

  // this function called if user navigate to ptient profile page
  const getPatientFromPatientID = () => {
    operation(
      {
        method: 'GET',
        url: urls.GET_A_PATIENT + encounterObj.patient,
        headers: { Authorization: `token ${token}` },
        data: {},
      },
      navigateToProfile,
      (error) => {console.log(error)}
    );
  }

  const navigateToProfile = (user) => {
    if(validateUserResponse(user)) {
      appCtx.addSelectedUser(user);
      const path = app_paths.PATIENT_PROFILE.replace(':patientID', encounterObj.patient)
      navigate(path);
    } else {
      console.log('invalid user response')
      Toastify({message: 'invalid user response', state: toastify_status.ERROR})
    }
  }

  // Reload encounter every 5 seconds for 2 minutes, stop the reload if the 2 minutes passed or the encounter is in final state (MRE and PBM are done).
  useEffect(() => {
    if(authCtx.isLoggedIn && encounterObj.actionmsg === ENCOUNTER_ACTIONMSG.Pending_approval && keepFetchingEncounter) {
      setTimeout(() => {
        reFetchEncounter()
        appCtx.handleReFetchChartsData()
      }, reload_encounter.FREQUENCY)

      setTimeout(() => {
        setKeepFetchingEncounter(false)
      }, reload_encounter.DURATION)
    }
    // eslint-disable-next-line
  }, [encounterObj])

  return (
    <Accordion
      expanded={encounter.expanded}
      sx={ encounterObj?.actionid === -1
            ? classes.pending_approval_accordion
            : encounterObj?.actionid === 0
            ? classes.accept_accordion
            : encounterObj?.actionid === 1
            ? classes.partially_accept_accordion
            : classes.reject_accordion
      }
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} dir={direction}>
        <Typography sx={classes.accordion_header}>{encounterObj?.date.split('T')[0]}</Typography>
        <Typography sx={classes.accordion_header}>
          {t('State')}: {
            encounterObj?.actionmsg === ENCOUNTER_ACTIONMSG.Approved
              ? t('Approved')
              : encounterObj?.actionmsg === ENCOUNTER_ACTIONMSG.Partially_accepted
              ? t("PartiallyAccepted")
              : encounterObj?.actionmsg === ENCOUNTER_ACTIONMSG.Rejected
              ? t("Rejected")
              : encounterObj?.actionmsg === ENCOUNTER_ACTIONMSG.Pending_approval
              ? t("PendingApproval")
              : 'not valid state'
          }
        </Typography>

        {userRole !== 'patient' && (
          <Typography sx={classes.accordion_header}>{t('Patient')}: {encounterObj.patient_name}</Typography>
        )}

      </AccordionSummary>

      {/* Diagnosis always exists in every encounter */}

      <AccordionDetails>
        <>
        <Box sx={classes.info}>
          {
            !disablePatientName && (
              <Typography sx={classes.info_text}>
                Patient:
                <span style={classes.pointer_info_text} onClick={getPatientFromPatientID}>
                  {encounterObj.patient_name}
                </span>
              </Typography>
            )
          }
          <Typography sx={classes.info_text} onClick={getPatientFromPatientID}>Provider: {encounterObj.provider_name}</Typography>
          <Typography sx={classes.info_text}>Reference Id: {encounterObj.reference_id} </Typography>
        </Box>

          <Typography sx={classes.encounter_title}>Encounter Diagnosis</Typography>
          {encounterObj?.encountericds.map((icd) => (
            <Box
              key={icd.id}
              sx={suboColor(icd)}
            >
              <Box sx={classes.encounter_point}>
                <FiberManualRecordIcon sx={classes.dot_icon} />
                <Typography>
                  ICD: ({icd.icd}){icd.primary ? ' (Primary)' : ''}[ {icd.shortdescription} ] [ Cost: {icd.cost}]
                </Typography>
              </Box>
              {<RenderIcdCptActionMessages actionMessages={icd.actionmsg}/>}
            </Box>
          ))}
        </>

        {/* Check encounter engine and role and cpts to render Procedures*/}

        {(encounterObj?.engine === 'MRE' || encounterObj?.engine === 'ALL') && userRole !== 'pharmacy_admin' && encounterObj?.encountercpts.length > 0 && (
          <>
            <Typography sx={classes.encounter_title}>Encounter Procedures</Typography>
              {encounterObj?.encountercpts.map((cpt) => (
                <Box
                  key={cpt.id}
                  sx={suboColor(cpt)}
                >
                  <Box sx={classes.encounter_point}>
                    <FiberManualRecordIcon sx={classes.dot_icon} />
                    <Typography>
                      {' '}
                      CPT: ( {cpt.cpt} ) [ {cpt.shortdescription} ]  [ Cost: {cpt.cost}]
                    </Typography>
                  </Box>
                  {<RenderIcdCptActionMessages actionMessages={cpt.actionmsg}/>}
                </Box>
              ))}
          </>
        )}

        {/* Check encounter engine and role and drugs to render Medications */}

        {(encounterObj?.engine === 'PBM' ||  encounterObj?.engine === 'ALL') && encounterObj?.encounterdrugs.length > 0  && (
          <>
            <Typography sx={classes.encounter_title}>Encounter Medications</Typography>
            {encounterObj?.encounterdrugs.map((drug) => (
              <Box
                key={drug.id}
                sx={suboColor(drug)}
              >
                <Box sx={classes.encounter_point}>
                  <FiberManualRecordIcon sx={classes.dot_icon} />
                  <Typography>
                    Drug: [ {drug.packagename} ] ( cost: {drug.cost} )
                  </Typography>
                </Box>

                <Box sx={classes.claimed_quantity_box}>
                  <Typography sx={classes.claimed_quantity}>Claimed Quantity <span style={classes.claimed_quantity_value}>{drug.claimed_quantity}</span></Typography>
                  <Typography sx={classes.claimed_quantity}>Approved Quantity <span style={classes.claimed_quantity_value}>{drug.approved_quantity}</span></Typography>
                  <Typography sx={classes.claimed_quantity}>Rejected Quantity <span style={classes.claimed_quantity_value}>{drug.rejected_quantity}</span></Typography>
                </Box>

                {<RenderDrugActionMessages actionMessages={drug.actionmsg} />}
              </Box>
            ))}
          </>
        )}

        <Typography sx={classes.encounter_title}>Encounter Notes:</Typography>
        {encounterObj?.notes ? (
          <Box>
            <p> {encounterObj.notes}</p>
          </Box>
        ) : (
          <Typography sx={classes.encounter_message}>No Notes</Typography>
        )}

        <Box sx={[classes.reloadBox]}>
          <Typography sx={[classes.reloadBtn, encounterObj?.actionmsg === ENCOUNTER_ACTIONMSG.Approved
            ? classes.btn_accepted_reload
            : encounterObj?.actionmsg === ENCOUNTER_ACTIONMSG.Rejected
              ? classes.btn_rejected_reload
              : classes.btn_partially_accept_reload
          ]} onClick={reFetchEncounter}>
            <CachedIcon sx={classes.reloadIcon} />
            {t('Reload')}
          </Typography>
        </Box>

        {loadingEcounter && (
          <CircularProgress
            sx={[
              classes.circular,
              encounterObj?.actionmsg === ENCOUNTER_ACTIONMSG.Approved
                ? classes.accepted_reload
                : encounterObj?.actionmsg === ENCOUNTER_ACTIONMSG.Rejected
                ? classes.rejected_reload
                : classes.partially_accept_reload
            ]}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default EncounterCard;
