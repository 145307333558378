
import  { useContext, useMemo } from 'react';
import  AxiosContext  from '../contexts/axios-context'

import axios from "axios";

export const useAxios = () => {
    const contextInstance = useContext(AxiosContext);
    const instance = useMemo(() => {
      return contextInstance || axios;
    }, [contextInstance]);

    const operation = async(params, cbResponse,cbError) => {
        try {
            // const result = await instance.request({
            //     ...params,
            //     transitional: {
            //         silentJSONParsing: false
            //     }
            // });

            const result = await instance.request(params)
            cbResponse(result.data)
        } catch (error) {
            cbError(error)
        }
    };
    return { operation };
};

export default useAxios;
