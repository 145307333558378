import { useTheme } from '@emotion/react';

const MainNavigationStyle = () => {
  const theme = useTheme();

  return{
    header_container:{ 
    height: "83px",
    width: "100%"
  },
  header: {
    height: "100%",
    maxWidth: {xs: "100%"},
    display: { xs: "none", md: "flex" },
    direction: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: "32px"
  },
  title: {
    fontSize: "24px",
    fontWeight: "500", 
    textTransform: "capitalize"
  },
  profile_logout: {
    display: "flex",
    flexDirection: "row",
    gap: '24px',
    alignItems: 'center',
  },
  logout_button: {
    padding: "10px 30px",
    width: '150px'
  },
  insurance_settings: {
    padding: "10px 36px",
  },
  profile_avatar: {
    width: "45px",
    height: "45px",
    border: '1.5px solid',
    borderColor: theme.palette.primary.main,
    cursor: 'pointer'
  },
  res_nav_container: {
    display: { xs: "flex", md: "none" },
    flexDirection: "row",
    alignItems: "center",
    gap: "24px",
    height: "100%",
    marginLeft: '24px',
  },
  logo : {
    height: "48px",
    width: '107px',
    marginLeft:"5px"
  },
  divider: {
    marginLeft: "-32px"
  },
  settingComponent: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    cursor: 'pointer',
    '&:hover': { color: '#4ABBA7' }
  },
  settingComponentText: { fontWeight: '500', marginLeft: '10px' },
  navigationMenu: { padding: '20px' },
  headerNavigationMenu: { display: 'flex', alignItems: 'center', marginBottom: '20px', paddingBottom: '10px', borderBottom: '1px solid black' },
  menuAvatar: { marginRight: '20px' },
  avatar: { width: '50px', height: '50px' },
  headerText: { fontWeight: '600', marginBottom: '4px' }
};

};

export default MainNavigationStyle;