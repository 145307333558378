import { useTheme } from '@emotion/react';

const MachineLearningPageStyle = () => {
  const theme = useTheme();

  return {
    container: {
      padding: '32px',
      border: '1px solid #d9d9d9',
      borderRadius: '10px',
      marginBottom: '32px',
    },
    title: {
      fontWeight: '500',
      fontSize: '21px',
      marginBottom: '32px',
    },
    pie_chart_box: {
      margin: '16px 0',
      minHeight: '250px',
    },
    bar_chart_box: {
      padding: '40px',
      border: '1px solid #d9d9d9',
      borderRadius: '10px',
      margin: '32px 0',
    },
    arrow_button: {
      marginTop: '-32px',
      marginLeft: '-12px',
    },
    arrow_icon: {
      fontSize: '36px',
      color: theme.palette.primary.main,
    },
  };
};

export default MachineLearningPageStyle;
