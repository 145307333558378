import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EncounterCardStyle from './EncounterCard.module';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// sample drug action messages
// import {sampleDrugActionMessages} from './sampleDrugActionMessages'

const RenderDrugActionMessages = ({ actionMessages }) => {
  const classes = EncounterCardStyle();
  // use sample drug action messages
  // const actionMessages = sampleDrugActionMessages.msg4

  // function to check object and msg inside td_msgs & dci_msgs & ddi_msgs & extra_msgs
  const isValidMsg = (msgObj) => {
    return (
      typeof msgObj === 'object' &&
      typeof msgObj.msg === 'string' &&
      msgObj.msg.trim().length > 0
    );
  };

  // function to validate action messages object
  const isValidActionMessages = (data) => {
    if (data && typeof data === 'object' && data["status"]) {
      const status = data["status"];
      if (status && typeof status === 'object' && status["state"]) {
        if (status["state"] === 'rejected') {
          const messages = status["messages"];
          if (messages && typeof messages === 'object') {
            const td_msgs = messages["td_msg"];
            const dci_msgs = messages["dci_msg"];
            const ddi_msgs = messages["ddi_msg"];
            const gi_msgs = messages["gi_msg"];
            const extra_msgs = messages["extra_msgs"];
            const di_msgs = messages["di_msg"];

            // check if all of td, dci, ddi, gi, extra messages are exist then validate
            if (td_msgs && dci_msgs && ddi_msgs && gi_msgs && extra_msgs && di_msgs) {
              if (
                Array.isArray(td_msgs) &&
                Array.isArray(dci_msgs) &&
                Array.isArray(ddi_msgs) &&
                Array.isArray(gi_msgs) &&
                Array.isArray(extra_msgs) &&
                Array.isArray(di_msgs)
              ) {
                // check each object in td_msgs
                const isValidTdMsgs = td_msgs.every(isValidMsg);

                // check each object in dci_msgs
                const isValidDciMsgs = dci_msgs.every(isValidMsg);

                // check each object in ddi_msgs
                const isValidDdiMsgs = ddi_msgs.every(isValidMsg);

                // check each object in gi_msgs
                const isValidGiMsgs = gi_msgs.every(isValidMsg);

                // check each object in extra_msgs
                const isValidExtra_msgs = extra_msgs.every(isValidMsg);

                // check each object in di_msgs
                const isValidDi_msgs = di_msgs.every(isValidMsg);

                return isValidTdMsgs && isValidDciMsgs && isValidDdiMsgs && isValidGiMsgs && isValidExtra_msgs && isValidDi_msgs;
              }
            } else {
              // check if only extra messages are exist then validate
              if (Array.isArray(extra_msgs)) {
                // check each object in extra_msgs
                const isValidExtra_msgs = extra_msgs.every(isValidMsg);
                return isValidExtra_msgs;
              }
            }

          }
        } else {
          return true
        }
      }
    }
    return false;
  };

  if (isValidActionMessages(actionMessages)) {
    const state = actionMessages["status"]["state"];

    if (state === 'approved') {
      return null
    } else if (state === 'rejected') {
      const title = {
        'td_msg': 'Therapeutic Duplications',
        'dci_msg': 'Drug Counter Indications',
        'ddi_msg': 'Drug to Drug Interactions',
        'gi_msg': 'Gender Interactions',
        'di_msg': 'Drug Indications',
        'extra_msgs': 'More Messages',
      }

      const objectKeys = Object.keys(actionMessages['status']['messages'])
      // map throw action messages.status.messages wich is object contain list of messages, to get the keys of this messages
      // and the result like ["di_msg", "gi_msg", "td_msg", "dci_msg", "ddi_msg", "extra_msgs"]

      return (
        <Box>
          {objectKeys.map((key, i) => {
            // chack if messages list more than 0 this mean there is message
            return actionMessages['status']['messages'][key].length > 0 && (
              <Box key={i}>
                {/* show drug action message title */}
                <Typography sx={classes.encounter_message}>{title[key]}</Typography>
                {/* show drug action message content */}
                {actionMessages['status']['messages'][key].map((msg, i) => {
                  return (
                    <Box key={i} sx={classes.drug_messages_point}>
                      <FiberManualRecordIcon sx={classes.drug_dot_icon} />
                      <Typography>{msg.msg}</Typography>
                      {msg['Severity level'] && (
                        <>
                          <Box sx={{ paddingTop: '5px' }}>
                            <PlayArrowIcon sx={{ fontSize: '15px' }} />
                          </Box>
                          <Typography> Severity level: {msg['Severity level']}</Typography>
                        </>
                      )}
                    </Box>
                  )
                })}
              </Box>
            )
          })}
        </Box>
      )
    } else {
      // show any state that not approved or pending approval or rejected
      return <Typography sx={classes.encounter_message}>{state}</Typography>;
    }
  } else {
    return <Box sx={[classes.drug_messages_point, classes.error_msg]}>
    <FiberManualRecordIcon sx={classes.drug_dot_icon} />
    <Typography>Message cannot be shown</Typography> 
  </Box>
  }
};

export default RenderDrugActionMessages;