import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ChartsStyle from './Charts.Module.js'
import { Chart } from "react-google-charts";
import CircularProgress from '@mui/material/CircularProgress';

const PieChartGoogle = ({ data, title, slices, notFoundDataMessage }) => {
    const classes = ChartsStyle()
    const options = {
        slices,
        legend: {'position':'right','alignment':'center'},
    };

    if (!data) {
        return <Box sx={classes.notFoundMessageContainer}>
          <Typography sx={classes.notFoundMessageTitle}><CircularProgress/></Typography>
        </Box>
      }

    if (Array.isArray(data) && data.length === 0) {
        return <Box sx={classes.notFoundMessageContainer}>
          <Typography sx={classes.notFoundMessageTitle}>{notFoundDataMessage}</Typography>
        </Box>
      }

    return (
        <Box>
            <Typography sx={classes.pieTitle}>{title}</Typography>
            {data && <Chart
                chartType="PieChart"
                data={data || []}
                options={options}
                width={"100%"}
                height={"400px"}
            />}
        </Box>

    );
}

export default PieChartGoogle
