import BarsDataset from "./BarChart";
import { useContext, useState, useEffect } from 'react';
import AuthContext from "../../contexts/auth-context";
import AppContext from "../../contexts/app-context";
import useAxios from "../../hooks/useAxios";
import { urls, toastify_status } from '../config/variables';
import { validateDoctorExpenditure } from "../../Utils/validateHttpResponse";
import Toastify from "../ui/Toastify";

const DoctorExpenditureBarChart = () => {
    const appCtx = useContext(AppContext)
    const searchByDateQuery = appCtx.searchByDateQuery
    const reFetchChartsData = appCtx.reFetchChartsData
    const [doctorExpenditure, setDoctorExpenditure] = useState('')
    const { operation } = useAxios();
    const authCtx = useContext(AuthContext);
    const token = authCtx.token;

    useEffect(() => {
        getDoctorExpenditure()
        // eslint-disable-next-line
    }, [searchByDateQuery, reFetchChartsData])

    const getDoctorExpenditure = () => {
        operation(
            {
                method: 'GET',
                url: urls.DOCTOR_PRICES + searchByDateQuery,
                headers: { Authorization: `token ${token}` },
                data: {},
            },
            handleGetDoctorExpenditure,
            (err) => console.log(err)
        );
    }

    const handleGetDoctorExpenditure = (response) => {
        if(validateDoctorExpenditure(response)) {
            const formattedData = response.map((item, i) => ({
                Total: Number(item.total_cost),
                Accepted: Number(item.accepted_cost),
                Rejected: Number(item.rejected_cost),
                provider: item.provider
            }))
            setDoctorExpenditure(formattedData);
        } else {
            Toastify({message: 'invalid doctor expenditure data response', state: toastify_status.ERROR})
          }
    }

    return <BarsDataset dataset={doctorExpenditure} title='Doctor Expenditure' notFoundDataMessage='No Doctor Expenditure'/>

}

export default DoctorExpenditureBarChart
