import { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import useAxios from '../hooks/useAxios'
import AuthContext from '../contexts/auth-context';
import FlatList from 'flatlist-react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import MachineLearningPageStyle from './MachineLearning.module';

const MachineLearningPage = () => {
  const [data, setData] = useState({field1: ""});
  const [response, setResponse] = useState(undefined);
  const [error, setError] = useState(undefined);
  const { operation } = useAxios();
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const classes = MachineLearningPageStyle();


  const handleChange = (e) => {
    setData({field1: e.target.value});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    operation({
      method: 'POST',
      url: '/api/patient/encounter/infer/create',
      headers: { Authorization: `token ${authCtx.token}` },
      data: {
        content: data.field1,
        response: ""
      }
    }, setResponse, setError);
  };

  /* Error should be handled properly later  */
  useEffect(() => {
    if (typeof error !== 'undefined') {
      console.log (error)
    }
  }, [error]);


  const reformateData = (entities) => {
    return entities.map((entity) => {
      return (
        <div>
          <p> {entity.Category} :  <b> {entity.Text} </b> </p>
          <p>  -------------------------------------------------- </p>
          <FlatList
            list={entity.ICD10CMConcepts}
            renderItem={(item) => (
              <li key={item.Description}>
                ICD: ({item.Code}) : {item.Description}
              </li>
            )}
          />
          <br/>
          <br/>
        </div>
      );
    });
  };

  return (
    <Box>
      <IconButton size="small" sx={classes.arrow_button} onClick={() => navigate(-1)}>
        <ArrowBackIcon fontSize="small" sx={classes.arrow_icon} />
      </IconButton>

      <h2> Medical Text Analysis</h2>
      <br/>
      <br/>
      <h4> Example </h4>
      <p> 
        Mr . Nesser is a 52 - year - old Caucasian male with an extensive past medical history that includes coronary artery disease , atrial fibrillation , hypertension , hyperlipidemia , presented to North ED with complaints of chills , nausea , acute left flank pain and some numbness in his left leg.
      </p>
      <br/>
      <br/>
      <form onSubmit={handleSubmit}>
          <textarea
            name="postContent"
            rows={10}
            cols={100}
            onChange={handleChange}
            //value="Mr . Nesser is a 52 - year - old Caucasian male with an extensive past medical history that includes coronary artery disease , atrial fibrillation , hypertension , hyperlipidemia , presented to North ED with complaints of chills , nausea , acute left flank pain and some numbness in his left leg."
          />
        <br/>
        <button>Submit</button>
      </form>
      <br/>
      <br/>
      {response &&    reformateData (response.response.Entities) }

    </Box>
  );
};

export default MachineLearningPage;
