import Joi from 'joi';

const LoginFormSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .min(5)
    .max(100)
    .messages({
      'string.empty': 'Email is required',
      'string.email': 'Invalid email format',
      'string.min': 'Email must be at least 5 characters',
      'string.max': 'Email must be at most 100 characters',
    }),
  password: Joi.string().min(8).max(30).pattern(new RegExp('^(?=.*[!@#$%^&*])')).messages({
    'string.empty': 'Password is required',
    'string.min': 'Password must be at least 8 characters',
    'string.max': 'Password must be at most 30 characters',
    'string.pattern.base': 'Password must include one of the following characters: ! @ # $ % ^ & *.',
  }),
});

export default LoginFormSchema;
