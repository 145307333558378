import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EncounterCardStyle from './EncounterCard.module';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const renderIcdActionMessages = ({ actionMessages }) => {
  const classes = EncounterCardStyle();

  // function to check object and msg inside extra_msgs
  const isValidMsg = (msgObj) => {
    return (
      typeof msgObj === 'object' &&
      typeof msgObj.msg === 'string' &&
      msgObj.msg.trim().length > 0
    );
  };

  // function to validate action messages object
  const isValidActionMessages = (data) => {
    if (data && typeof data === 'object' && data["status"]) {
      const status = data["status"];
      if (status && typeof status === 'object' && status["state"]) {
        if (status["state"] === 'rejected') {
          const messages = status["messages"];
          if (messages && typeof messages === 'object') {
            const extra_msgs = messages["extra_msgs"];
            if (Array.isArray(extra_msgs)) {
              // check each object in extra_msgs
              const isValidExtra_msgs = extra_msgs.every(isValidMsg);
              return isValidExtra_msgs;
            }
          }
        } else {
          return true
        }
      }
    }
    return false;
  };

  if (isValidActionMessages(actionMessages)) {
    const state = actionMessages["status"]["state"];

    if (state === 'approved') {
      return null;
    } else if (state === 'rejected') {
      const title = {
        'extra_msgs': 'More Messages',
      }

      const objectKeys = Object.keys(actionMessages['status']['messages'])
      // map throw action messages.status.messages wich is object contain list of messages, to get the keys of this messages
      // and the result like ["extra_msgs"]
      return (
        <Box>
          {objectKeys.map((key, i) => {
            // chack if messages list more than 0 this mean there is message
            return actionMessages['status']['messages'][key].length > 0 && (
              <Box key={i}>
                {/* show icdcpt action message title */}
                <Typography sx={classes.encounter_message}>{title[key]}</Typography>
                {/* show icdcpt action message content */}
                {actionMessages['status']['messages'][key].map((msg, i) => {
                  return <Box key={i} sx={classes.drug_messages_point}>
                    <FiberManualRecordIcon sx={classes.drug_dot_icon} />
                    <Typography>{msg.msg}</Typography>
                  </Box>
                })}
              </Box>
            )
          })}
        </Box>
      )
    } else {
      // show any state that is not approved or rejected
      return <Typography sx={classes.encounter_message}>{state}</Typography>;
    }
  } else {
    return (
      <Box sx={[classes.drug_messages_point, classes.error_msg]}>
        <FiberManualRecordIcon sx={classes.drug_dot_icon} />
        <Typography>Message cannot be shown</Typography>
      </Box>
    );
  }
};

export default renderIcdActionMessages;
