import { useContext, useState, useMemo, useEffect, memo } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import NewEncounterFormStyle from './NewEncounterForm.module';
import useAxios from '../../../hooks/useAxios';
import AuthContext from '../../../contexts/auth-context';
import { urls, PAGE_SIZE, MIN_NUMBER_OF_SEARCH, toastify_status } from '../../config/variables';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next'
import Button from '@mui/material/Button';
import MedicalSelections from './MedicalSelections';
import { validateDrugsCode, validateAlternativeDrugs, validateRelatedDrugs } from '../../../Utils/validateHttpResponse';
import Toastify from '../../ui/Toastify';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const DrugName = memo(({ index, handleSelectDrug, isDisabled, clearSearch, handleDeleteDrugValue, getSelectedOptionObject, setProductId, diagnoses, favourite, updateFavourite }) => {
  const classes = NewEncounterFormStyle();
  const { operation } = useAxios();
  const authCtx = useContext(AuthContext);
  const [product_id, setproduct_id] = useState('');
  const [drugOptions, setDrugOptions] = useState([]);
  const [drugOptionsError, setDrugOptionsError] = useState(undefined);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectInputLabel, setSelectInputLabel] = useState('Please search first before selecting.');
  const {t} = useTranslation('newEncounter')
  // alternative drug options states
  const [alternativeDrugOptions, setAlternativeDrugOptions] = useState([])
  const [alternativeDrugOptionsError, setAlternativeDrugOptionsError] = useState(undefined);
  const [openAlternativeDrugsPopup, setOpenAlternativeDrugsPopup] = useState(false);
  // isAlternative to check if the selected drug is alternative or no to take the drug label from the options list (drugOptions or alternativeDrugOptions)
  const [isAlternative, setIsAlternative] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [formattedDiagnoses, setFormattedDiagnoses] = useState([]);
  // related drugs
  const [relatedDrugOptions, setRelatedDrugOptions] = useState([]);
  const [relatedDrugOptionsError, setRelatedDrugOptionsError] = useState(undefined);
  const [openRelatedDrugOptions, setOpenRelatedDrugOptions] = useState(false);
  const [loadingRelatedDrugs, setLoadingRelatedDrugs] = useState(false);
  const [isRelated, setIsRelated] = useState(false);
  // favourite drugs
  const [favouriteDrugOptions, setFavouriteDrugOptions] = useState([])
  const [openFavouriteDrugsDialog, setOpenFavouriteDrugsDialog] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  // check if the selected diagnoses is a favourite diagnose or no
  const [selectedFavouriteDrug, setsSelectedFavouriteDrug] = useState(false);
  const [loadingFavourite, setLoadingFavourite] = useState(false);

  useEffect(() => {
    if(Array.isArray(favourite.drugs)) {
      setFavouriteDrugOptions(favourite.drugs)
      setLoadingFavourite(false)
    }
  }, [favourite])

  const handleSetDrugOptions = (resDrugOptions) => {
    if(validateDrugsCode(resDrugOptions)) {
      setDrugOptions(resDrugOptions);
      resDrugOptions.length > 0
        ? setSelectInputLabel(<Typography sx={classes.found_options}>Select an option..</Typography>)
        : setSelectInputLabel(<Typography sx={classes.not_found_options}>No Drugs found for "{searchQuery}".</Typography>);
      } else {
        console.log('invalid diagnosis response')
        Toastify({message: 'invalid diagnosis response', state: toastify_status.ERROR})
      }
    };

  const handleSubmit = () => {
    setIsAlternative(false)
    setIsRelated(false)
    setIsFavourite(false)
    setPage(1)
    setAlternativeDrugOptions([])
    if (searchQuery.length >= MIN_NUMBER_OF_SEARCH) {
      setDrugOptionsError('');
      operation(
        {
          method: 'POST',
          url: urls.LIST_SEARCHED_DRUGS,
          headers: { Authorization: `token ${authCtx.token}` },
          data: {"searchfor": searchQuery},
        },
        handleSetDrugOptions,
        setDrugOptionsError
      );
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    if (typeof value !== 'undefined') {
      setSearchQuery(value);
    }
  };

  const handleClearDrug = () => {
    setSearchQuery('');
      setDrugOptions([]);
      setAlternativeDrugOptions([])
      setproduct_id('')
      handleDeleteDrugValue(index);
      setDrugOptionsError(undefined);
      setSelectInputLabel('Please search first before selecting.');
      getSelectedOptionObject(null);
      setsSelectedFavouriteDrug(false)
  }

  const handleSelectOption = (event, index, selectedOptionsResource) => {
    // selectedOptionsResource is a string pass over from (selectAlternativeDrugOption or selectRelatedDrugOption Functions)
    setproduct_id(event.target.value)
    // set product id is a function comes from TreatmentForm page
    setProductId(event.target.value)
    handleSelectDrug(event, index);
    const options =
    selectedOptionsResource === 'alternative'
        ? alternativeDrugOptions
        : selectedOptionsResource === 'related'
        ? relatedDrugOptions
        : selectedOptionsResource === 'favorite'
        ? favouriteDrugOptions
        : drugOptions;

    const selectedOptionObject = options.find((option) => option.product_id === event.target.value);
    getSelectedOptionObject(selectedOptionObject);

    switch (selectedOptionsResource) {
      case 'alternative':
        setDrugOptions([{...selectedOptionObject}]);
        setOpenAlternativeDrugsPopup(false);
        setIsAlternative(true);
        setIsRelated(false);
        setIsFavourite(false);
        break;

      case 'related':
        setDrugOptions([{...selectedOptionObject}]);
        setOpenRelatedDrugOptions(false);
        setIsRelated(true);
        setIsAlternative(false);
        setIsFavourite(false);
        break;

      case 'favorite':
        setDrugOptions([{...selectedOptionObject}]);
        setOpenFavouriteDrugsDialog(false);
        setIsFavourite(true);
        setIsRelated(false);
        setIsAlternative(false);
        break;

      default:
        break;
    }
  };

  const drugsMenuItems = useMemo(() => {
    // select the options list to show the selected drug label
    const options = isAlternative ? alternativeDrugOptions : isRelated ? relatedDrugOptions : isFavourite ? favouriteDrugOptions : drugOptions
    // find the product id that match the selected options list
    const foundPoductId = options.find((option) => option.product_id === product_id)
    // if the product id from alternative list and the selected list is drugOptions then set the product is to empty
    if(!foundPoductId) {
      setproduct_id('')
    }
    return options.map((option) => (
      <MenuItem key={option.product_id} value={option.product_id}>
        {option.product_label_name} - pkg size {parseInt(option.product_package_size)}
      </MenuItem>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drugOptions, isAlternative, product_id, isRelated, isFavourite]);

  useEffect(() => {
    setSearchQuery('');
    setDrugOptions([]);
    setAlternativeDrugOptions([])
    setDrugOptionsError(undefined);
    setSelectInputLabel('Please search first before selecting.');
  }, [clearSearch]);

  const handleOpenAlternativeDrugsPopup = () => {
    setOpenAlternativeDrugsPopup(true);
  };

  const handleCloseAlternativeDrugsPopup = () => {
    setOpenAlternativeDrugsPopup(false);
  };

  const selectAlternativeDrugOption = (drug) => {
    const event = {target: {name: 'drug', value: drug.product_id}}
    handleSelectOption(event, index, 'alternative')
    setSearchQuery(drug.product_label_name)
  }

  useEffect(() => {
    if(product_id) {
      fetchAlternativeDrugOptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_id, page])

  const fetchAlternativeDrugOptions = () => {
    setLoading(true)
      operation(
        {
          method: 'GET',
          url: `${urls.ALTERNATIVE_DRUG_OPTIONS}${product_id}?page=${page}&page_size=${PAGE_SIZE}`,
          headers: { Authorization: `token ${authCtx.token}` },
          data: {},
        },
        getAlternativeDrugOptions,
        setAlternativeDrugOptionsError
      );
  };

  const getAlternativeDrugOptions = (alternativeOptions) => {
    setLoading(false)
    if(validateAlternativeDrugs(alternativeOptions)) {
      setAlternativeDrugOptions(alternativeOptions.results)
      setCount(Math.ceil(alternativeOptions.count / 10));
    } else {
      console.log('invalid alternative drugs response')
      Toastify({message: 'invalid alternative drugs response', state: toastify_status.ERROR})
    }
  }

  // formate selected diagnoses to use it to fetch related drugs
  useEffect(() => {
    if (diagnoses && Array.isArray(diagnoses)) {
      setFormattedDiagnoses(diagnoses.filter((diagnose) => diagnose && diagnose.icd).map((diagnose) => diagnose.icd))
    }
  }, [diagnoses])

  const fetchRelatedDrugOptions = () => {
    setLoadingRelatedDrugs(true)
    setRelatedDrugOptionsError(undefined)
      operation(
        {
          method: 'POST',
          url: urls.LIST_SEARCHED_DRUGS,
          headers: { Authorization: `token ${authCtx.token}` },
          data: {"searchfor": '',
            "icd": formattedDiagnoses},
        },
        handleSetRelatedDrugOptions,
        handleSetRelatedDrugOptionsError
      );
  }

  const handleSetRelatedDrugOptions = (relatedDrugs) => {
    setLoadingRelatedDrugs(false)
    if(validateRelatedDrugs(relatedDrugs)) {
      setRelatedDrugOptions(relatedDrugs)
    } else {
      Toastify({message: 'invalid related drugs response', state: toastify_status.ERROR})
    }
  }

  const handleSetRelatedDrugOptionsError = (relatedDrugsError) => {
    setRelatedDrugOptionsError(relatedDrugsError)
    setRelatedDrugOptions([])
    setLoadingRelatedDrugs(false)
  }

  useEffect(() => {
    if(formattedDiagnoses.length > 0) {
      fetchRelatedDrugOptions()
    } else {
      setRelatedDrugOptions([])
    }
    // eslint-disable-next-line
  }, [formattedDiagnoses])

  const handleOpenRelatedDrugsPopup = () => {
    setOpenRelatedDrugOptions(true);
  };

  const handleCloseRelatedDrugsPopup = () => {
    setOpenRelatedDrugOptions(false);
  };

  const selectRelatedDrugOption = (drug) => {
    const event = {target: {name: 'drug', value: drug.product_id}}
    handleSelectOption(event, index, 'related')
    setSearchQuery(drug.product_label_name)
  }

  // Favorite Drugs

  const handleOpenFavouriteDrugsPopup = () => {
    setOpenFavouriteDrugsDialog(true);
  };

  const handleCloseFavouriteDrugsPopup = () => {
    setOpenFavouriteDrugsDialog(false);
  };

  const selectFavouriteDrug = (drug) => {
    const event = {target: {name: 'drug', value: drug.product_id}}
    handleSelectOption(event, index, 'favorite')
    setSearchQuery(drug.product_label_name)
  }

  // check if the selected diagnoses is a favourite diagnose or no
  useEffect(() => {
    if (product_id) {
      setsSelectedFavouriteDrug(favouriteDrugOptions.find((drug) => drug.product_id === product_id));
    }
  }, [product_id, favouriteDrugOptions]);

  const addFavouriteDrug = () => {
    const favouriteDrugList = favouriteDrugOptions.map((drug) => drug.product_id)
    const updatesDrugsList = [...favouriteDrugList, product_id]
    const data = {'drugs': updatesDrugsList}
    updateFavourite(data)
    setLoadingFavourite(true)
  }

  const RemoveFavouriteDrug = () => {
    const updatedDrugList = favouriteDrugOptions.map((drug) => drug.product_id).filter((drug) => drug !== product_id)
    const data = {'drugs': updatedDrugList}
    updateFavourite(data)
    setLoadingFavourite(true)
  }

  return (
    <Box sx={classes.container}>
      <Box sx={classes.input_label_container}>
        <InputLabel disabled={isDisabled} sx={classes.input_label}>
          {t("MedicationName")}
        </InputLabel>
        <Box sx={classes.input_label_container_buttons_box}>
            <Button
              sx={classes.open_dialog_button}
              onClick={handleOpenRelatedDrugsPopup}
              disabled={!loadingRelatedDrugs && relatedDrugOptions.length === 0}
            >
              {!loadingRelatedDrugs && relatedDrugOptions.length === 0 ? (
                'No related drugs'
              ) : (
                <>
                  Related Drugs
                  {loadingRelatedDrugs && (
                    <CircularProgress
                      size={14}
                      sx={classes.input_label_container_button_circular_progress}
                    />
                  )}
                </>
              )}
            </Button>

            <Button
              disabled={alternativeDrugOptions.length === 0}
              sx={classes.open_dialog_button}
              onClick={handleOpenAlternativeDrugsPopup}>
              {t("Alternative")}
            </Button>

            <Button
              sx={classes.open_dialog_button}
              onClick={handleOpenFavouriteDrugsPopup}
              disabled={isDisabled || (!loadingFavourite && favouriteDrugOptions.length === 0)}
            >
              Favorite Drugs
              {loadingFavourite && (
                <CircularProgress
                  size={14}
                  sx={classes.input_label_container_button_circular_progress}
                />
              )}
            </Button>
          </Box>
      </Box>


      <Box sx={classes.select_inputs_container}>
        <TextField
          disabled={isDisabled}
          variant="outlined"
          InputProps={{
            startAdornment: searchQuery && (
              <InputAdornment position="start">
                <IconButton onClick={handleClearDrug} sx={classes.clear_button}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSubmit} sx={classes.search_button} disabled={searchQuery.length < MIN_NUMBER_OF_SEARCH}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={searchQuery}
          onChange={handleSearch}
          placeholder={`Search for at least ${MIN_NUMBER_OF_SEARCH} characters`}
          sx={classes.search_input}
          size="small"
          error={!!drugOptionsError}
          helperText={drugOptionsError ? drugOptionsError.message : ''}
        />
        <FormControl sx={classes.select_input} size="small" disabled={isDisabled || drugOptions.length === 0}>
          {!product_id && <InputLabel shrink={false}>{selectInputLabel}</InputLabel>}
          <Select
            name="drug"
            value={product_id || ''}
            onChange={(event) => handleSelectOption(event, index)}
            MenuProps={{ style: classes.menu_props }}
            onOpen={() => {
              setIsAlternative(false)
              setIsRelated(false)
            }}
          >
            {drugsMenuItems}
          </Select>
        </FormControl>

          <Box sx={classes.star_box}>
            {loadingFavourite ? (
              <CircularProgress size={20} />
            ) : (
              <IconButton
                disabled={!product_id}
                onClick={selectedFavouriteDrug ? RemoveFavouriteDrug : addFavouriteDrug}
              >
                <Tooltip title={selectedFavouriteDrug ? 'Remove From Favourites' : 'Add To Favourites'}>
                  {selectedFavouriteDrug ? <StarIcon /> : <StarBorderIcon />}
                </Tooltip>
              </IconButton>
            )}
        </Box>

      </Box>
      <MedicalSelections
        selectionType='drug'
        title='Alternative Drug'
        open={openAlternativeDrugsPopup}
        handleClose={handleCloseAlternativeDrugsPopup}
        loading={loading}
        data={alternativeDrugOptions}
        error={alternativeDrugOptionsError}
        selectOption={selectAlternativeDrugOption}
        count={count}
        handlePageChange={(v) => setPage(v)}
        alternative={true}
      />

    <MedicalSelections
        selectionType='drug'
        title='Related Drug'
        open={openRelatedDrugOptions}
        handleClose={handleCloseRelatedDrugsPopup}
        loading={loadingRelatedDrugs}
        data={relatedDrugOptions}
        error={relatedDrugOptionsError}
        selectOption={selectRelatedDrugOption}
      />

      <MedicalSelections
        selectionType='drug'
        title='Favourite Drug'
        open={openFavouriteDrugsDialog}
        handleClose={handleCloseFavouriteDrugsPopup}
        loading={loadingRelatedDrugs}
        data={favouriteDrugOptions}
        selectOption={selectFavouriteDrug}
      />
    </Box>
  );
});

export default DrugName;
