import { useTheme } from '@emotion/react';

const UserProfileNavigationStyle = () => {
  const theme = useTheme();

  return {
    container: {
      display: 'flex',
      flexDirection: { xs: 'column', lg: 'row' },
      alignItems: { xs: 'start', lg: 'center' },
      justifyContent: 'space-between',
      gap: '20px',
      marginBottom: '24px',
    },
    patient_profile: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'start',
      gap: '24px',
    },
    patient_profile_button: {
      marginTop: '24px',
      maxWidth: '200px',
      fontWeight: '600',
      fontSize: '16px',
      padding: '10px 16px',
    },
    patient_profile_img: {
      width: '130px',
      height: '130px',
    },
    patient_profile_name: {
      fontSize: '32px',
      fontWeight: '400',
      textTransform: 'capitalize',
    },
    patient_info_container: {
      flexBasis: '50%',
      display: 'flex',
      flexDirection: { xs: 'row', lg: 'column' },
      gap: { xs: '10px', lg: '10px', sm: '10px' },
      flexWrap: 'wrap',
    },
    patient_info_box: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

    },
    patient_info_text: {
      flexBasis: { xs: '100%', lg: '100%' },
      padding: { xs: '4px 8px', sm: '12px 8px' },
      border: '1px solid #d9d9d9',
      borderRadius: '10px',
    },
    patient_info_span: {
      whiteSpace: 'nowrap',
      marginLeft: '4px',
      marginRight: '4px',
      color: theme.palette.custom.black['60'],
      flexBasis: '40%',
      borderRadius: '10px',
      textAlign: 'left',
    },
  };
};

export default UserProfileNavigationStyle;
