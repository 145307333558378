import { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ChartsStyle from '../Charts.Module.js';
import CircularProgress from '@mui/material/CircularProgress';

const chartSetting = {
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-20px, 0)',
    },
  },
};

export default function BarsDataset({ data, title, notFoundDataMessage, loadingData }) {
  const classes = ChartsStyle();
  const [period, setPeriod] = useState([]);
  const [dataset, setDataset] = useState([]);

  const marginTop = data 
    ? (data.length < 10 ? 100
      : data.length < 15 ? 150
      : data.length < 20 ? 200
      : 250)
    : 0;

  useEffect(() => {
    if (data && Array.isArray(data) && data.length > 0) {
      const updatedPeriod = data[0]['date_list'].map((d) => ({ period: d }));
      setPeriod(updatedPeriod);

      const updatedDataSet = data.map((d) => ({
        data: d.cost,
        stack: d.provider_name,
        label: `${d.provider_name} ${d.type}`,
      }));

      setDataset(updatedDataSet);
    }
  }, [data]);

  if (!data) {
    return (
      <Box sx={classes.circularProgress}>
        <CircularProgress size="50px" />
      </Box>
    );
  }

  if (Array.isArray(dataset) && dataset.length === 0) {
    return (
      <Box sx={classes.notFoundMessageContainer}>
        <Typography sx={classes.notFoundMessageTitle}>{notFoundDataMessage}</Typography>
      </Box>
    );
  }

  return (
    <>
      <Typography sx={classes.barTitle}>{title}</Typography>
      {dataset && dataset.length > 0 && (
        <BarChart
          margin={{ top: marginTop }}
          xAxis={[{ scaleType: 'band', dataKey: 'period' }]}
          dataset={period}
          series={dataset}
          height={500}
          {...chartSetting}
        />
      )}
    </>
  );
}
