import { useContext, useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import UserProfilePageStyle from './UserProfilePage.module';
import AuthContext from '../../contexts/auth-context';
import AppContext from '../../contexts/app-context';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { urls, PAGE_SIZE, app_paths, toastify_status, searchByStateButtons } from '../../components/config/variables';
import EncounterCard from '../../components/users/EncounterCard/EncounterCard';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import SearchInput from '../../components/ui/SearchInput';
import {useTranslation} from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress';
import { validateEncountersList } from '../../Utils/validateHttpResponse';
import Toastify from '../../components/ui/Toastify';

const DoctorProfilePage = () => {
  const classes = UserProfilePageStyle();

  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const appCtx = useContext(AppContext)
  const doctor = authCtx.userProfile.user;
  const direction = appCtx.direction

  const {t} = useTranslation('doctorProfilePage')

  const token = authCtx.token;
  const { operation } = useAxios();

  const [doctorEncounters, setDoctorEncounters] = useState(undefined);
  const [doctorEncountersError, setDoctorEncountersError] = useState(undefined);
  const [doctorEncountersCards, setDoctorEncountersCards] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [dateValidateError, setDateValidateError] = useState(false);
  const [loadingEcounters, setLoadingEncounters] = useState(true);
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const [disaabledSearchButton, setDisabledSearchButton] = useState(true);
  const [searchButtonClicked, setSearchButtonClicked] = useState(false);
  const [searchByState, setSearchByState] = useState('');
  
  const SearchByMenuItems = [
    { key: 1, label: t('Search.Date'), value: 'date' },
    { key: 2, label: t('Search.ICDCode'), value: 'icd_code' },
    { key: 3, label: t('Search.CPTCode'), value: 'cpt_code' },
    { key: 4, label: t('Search.State'), value: 'state' },
  ];
  
  const sortByDate = (array) => {
    return array.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA;
    });
  };

  const setFilteredDoctorEncounters = (encounters) => {
    if(validateEncountersList(encounters)) {
      setLoadingEncounters(false)
      const sortedDoctorEncounters = sortByDate(encounters.results);
      setDoctorEncounters(sortedDoctorEncounters);
      setDoctorEncountersCards(reformateData(sortedDoctorEncounters));
      setCount(Math.ceil(encounters.count / PAGE_SIZE));
    } else {
      console.log('invlid encounters list response')
      Toastify({message: 'invlid encounters list response', state: toastify_status.ERROR})
    }
    
  };

  const get_encounters = (filter) => {
    setLoadingEncounters(true)
    operation(
      {
        method: 'GET',
        url: urls.LIST_ENCOUNTERS + filter,
        headers: { Authorization: `token ${token}` },
        data: {},
      },
      setFilteredDoctorEncounters,
      setDoctorEncountersError
    );
  }

  useEffect(() => {
    let filter;
    if (searchButtonClicked) {
      if (searchBy === 'date' && fromDate && toDate) {
        filter = `?from_date=${fromDate}&to_date=${toDate}&page=${page}&page_size=${PAGE_SIZE}`;
      } else if (searchBy === 'state') {
        filter = `?state=${searchByState}&page=${page}&page_size=${PAGE_SIZE}`
      } else {
        const selectedSearchBy = searchBy === 'cpt_code' ? '?cpt=' : searchBy === 'state' ? '?state=' : '?icd=';
        filter = searchQuery
          ? `${selectedSearchBy}${searchQuery}&page=${page}&page_size=${PAGE_SIZE}`
          : `?page=${page}&page_size=${PAGE_SIZE}`;
      }
      get_encounters(filter);
    } else {
      // If search button was not clicked, use default filter for pagination
      get_encounters(`?page=${page}&page_size=${PAGE_SIZE}`);
    }
    // eslint-disable-next-line
  }, [page, searchButtonClicked]);


  const handleChangeSearchBy = (event) => {
    setSearchBy(event.target.value);
    setDateValidateError(false);
    setSearchQuery('');
    setFromDate('')
    setToDate('')
    setSearchByState('')
    setSearchButtonClicked(false)
    setPage(1)
  };

  const reformateData = (encounters) => {
    if (typeof encounters !== 'undefined') {
      return encounters.map((patientEncounter) => {
   
          return <EncounterCard key={patientEncounter.id} encounter={patientEncounter} />;
        
      });
    }
  };

  const handleChangeSearch = (e) => {
    const {value, name} = e.target

    if (searchBy === 'date') {
      const currentDate = new Date();
      const date = new Date(value);

      if (date < currentDate) {
        if(name === 'fromDate'){
          setFromDate(value);
        } else if (name === 'toDate'){
          setToDate(value);
        }
        setDateValidateError(false);
      } else {
        setDateValidateError(true);
      }

    } else {
      setSearchQuery(value);
    }
  };

  // set date validate to error if from date bigger than to date
  useEffect(() => {
    if(toDate){
      if(fromDate > toDate){
        setDateValidateError(true);
      } else {
        setDateValidateError(false);
      }
    } 
  }, [fromDate, toDate])

   // set disabled search button to true if there is date validate error of there is no search fromDate or searchQuery
   useEffect(() => {
    const isValidDate = fromDate && toDate && !dateValidateError;
    const isValidSearch = searchQuery && !dateValidateError;
  
    if (isValidDate || isValidSearch) {
      setDisabledSearchButton(false);
    } else {
      setDisabledSearchButton(true);
    }
  }, [fromDate, toDate, searchQuery, dateValidateError]);

  const handleSearch = (state) => {
    setPage(1);
    setSearchButtonClicked(true);
    if (searchBy === 'date') {
      get_encounters(`?from_date=${fromDate}&to_date=${toDate ? toDate : fromDate}&page=1&page_size=${PAGE_SIZE}`);
    } else if (searchBy === 'cpt_code') {
      get_encounters(`?cpt=${searchQuery}&page=1&page_size=${PAGE_SIZE}`);
    } else if (searchBy === 'icd_code') {
      get_encounters(`?icd=${searchQuery}&page=1&page_size=${PAGE_SIZE}`);
    } else if (searchBy === 'state') {
      get_encounters(`?state=${state}&page=1&page_size=${PAGE_SIZE}`);
    } else {
      console.log ("Unknown filter")
    }
  };

  const defaultSearch = () => {
    setSearchButtonClicked(false)
    if(page !== 1) {
      setPage(1);
    } else {
      get_encounters(`?page=${page}&page_size=${PAGE_SIZE}`)
    }
  }

  const handleClearSearch = () => {
      setSearchQuery('');
      setSearchBy('')
      setFromDate('')
      defaultSearch()
  };

  const handleClearDate = (value) => {
    if(value === 'fromDate'){
      setFromDate('')
    } else if (value === 'toDate'){
      setToDate('')
    }
    setDateValidateError(false);
    
    defaultSearch()
  }
 // this function called when select search by encounter state and click state button
  const handleSelectEncounterState = (btn) => {
    if(btn.name === searchByState) {
      setSearchByState('')
      defaultSearch()
    } else {
      handleSearch(btn.name)
      setSearchByState(btn.name)
    }
  }

  return authCtx.error ? (
    <Box dir={direction}>
      <Alert severity="error">
        <AlertTitle sx={classes.alertTitle}>{t('NoDoctorInfoTitle')}</AlertTitle>
         {t('NoDoctorInfoMessage')}
      </Alert>
    </Box>
  ) : (
    <Box>
      <IconButton size="small" sx={classes.arrow_button} onClick={() => navigate(-1)}>
        <ArrowBackIcon fontSize="small" sx={classes.arrow_icon} />
      </IconButton>
      <Box sx={classes.container}>
        <Box sx={classes.user_info_container} dir={direction}>
          <Box sx={classes.avatar_container}>
            <Avatar
              alt="user1"
              src={doctor?.gender === 'F' ? '/images/doctor_woman.svg' : '/images/doctor_man.svg'}
              sx={classes.user_avatar}
            />
            <Typography sx={classes.user_name}>{doctor?.name}</Typography>
          </Box>
          <Box sx={classes.user_details}>
            <Box sx={classes.user_info_box}>
              <Typography>
                {t('GovernmentID')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {doctor?.eid}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box}>
              <Typography>
                {t('Nationality')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {doctor?.nationality}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box}>
              <Typography>
                {t('DateOfBirth')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {doctor?.birthdate}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box}>
              <Typography>
               {t('Age')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {doctor?.age} Y
                </Typography>
            </Box>
            <Box sx={classes.user_info_box}>
              <Typography>
               {t('Gender')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {doctor?.gender === 'F' ? 'Female' : 'Male'}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box}>
              <Typography>
               {t('Employer')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {doctor?.employer}
                </Typography>
            </Box>
            {/* <Box sx={classes.user_info_box}>
              <Typography>
                Insurance ID
                <Typography component="span" sx={classes.user_info_value}>
                  {doctor?.insurance_id}
                </Typography>
              </Typography>
            </Box> */}
            <Box sx={classes.user_info_box}>
              <Typography>
               {t('MobileNumber')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {doctor?.phone_number}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box_extended}>
              <Typography>
               {t('EmailAddress')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {doctor?.email}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box_extended}>
              <Typography>
               {t('PhysicalAddress')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {doctor?.physical_address}
                </Typography>
            </Box>
          </Box>
        </Box>

        <Box dir={direction}>
          <NavLink to={app_paths.ANALYSIS}>
            <Button sx={classes.analysis_page_button} variant="contained">
              {t('AnalysisPage')}
            </Button>
          </NavLink>
        </Box>
        
        {doctorEncountersError ? (
          <Box dir={direction} sx={classes.alertBox}>
            <Alert severity="error">
              <AlertTitle sx={classes.alertTitle}>{t('EncountersNotFoundTitle')}</AlertTitle>
              {t('EncountersNotFoundMessage')}<br />
            </Alert>
          </Box>
          
        ) : (
          <Box sx={classes.user_encounter_container}>
            <Box dir={direction}>
             <Typography sx={classes.sub_title}>{t('EncountersHistory')}</Typography>
            </Box>
            <Box sx={classes.search_container}>
              <FormControl fullWidth sx={classes.search_by} size="small">
                <InputLabel>{t('Search.SearchBy')}</InputLabel>
                <Select
                  label={t('Search.SearchBy')}
                  value={searchBy}
                  onChange={handleChangeSearchBy}
                  disabled={!doctorEncounters || doctorEncounters.length === 0}
                >
                  {SearchByMenuItems.map((item) => (
                    <MenuItem key={item.key} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

                  {searchBy === 'date' ? (
                    <>
                      <SearchInput
                        handleSearch={handleSearch}
                        type={'date'}
                        value={fromDate}
                        name='fromDate'
                        handleChangeSearch={handleChangeSearch}
                        dateValidateError={dateValidateError}
                        label={t('Search.FromDate')}
                        handleClearDate={() => handleClearDate('fromDate')}
                      />
                      <SearchInput
                        handleSearch={handleSearch}
                        type={'date'}
                        value={toDate}
                        name='toDate'
                        handleChangeSearch={handleChangeSearch}
                        dateValidateError={dateValidateError}
                        label={t('Search.ToDate')}
                        handleClearDate={() => handleClearDate('toDate')}
                      />
                    </>
                  ) : searchBy === 'state' ? (
                    <Box sx={classes.searchByStateBox}>
                      {searchByStateButtons.map((btn, i) => {
                        return <Button
                          key={i}
                          sx={classes.searchByStateButtons}
                          name={btn.name}
                          variant={
                            searchByState === btn.name ? 'contained' : 'outlined'
                          }
                          color={btn.color}
                          onClick={() => handleSelectEncounterState(btn)}
                        >
                          {btn.name}
                        </Button>
                      })}
                    </Box>
                  ) : (
                    <SearchInput
                      handleSearch={handleSearch}
                      type={'text'}
                      value={searchQuery}
                      handleChangeSearch={handleChangeSearch}
                      dateValidateError={dateValidateError}
                      searchBy={searchBy}
                      searchQuery={searchQuery}
                      handleClearSearch={handleClearSearch}
                    />
                  )}
                  {searchBy !== 'state' && (
                    <Button disabled={disaabledSearchButton} variant="contained" onClick={handleSearch} sx={classes.search_button}>
                      {t("Search.Search")}
                    </Button>
                  )}
                </Box>

            {!loadingEcounters && doctorEncountersCards.length > 0 ?
              doctorEncountersCards :
              loadingEcounters ?
                <Box sx={classes.loadEncounters}>
                  <CircularProgress/>
                </Box>:
                <Box sx={classes.not_found_message}>
                  <SentimentDissatisfiedIcon />
                  <Typography>{t("PatientEncountersNotFoundTitle")}</Typography>
                </Box>
            }
                {!loadingEcounters && count > 1 && <Box sx={classes.pagination}>
                  <Stack spacing={2} sx={classes.stack}>
                    <Pagination page={page} disabled={count === 1} count={count} variant="outlined" color="primary" size="large" onChange={(e, v) => setPage(v)} />
                  </Stack>
                </Box>}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DoctorProfilePage;
