import { useRef, useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import AuthContext from '../../contexts/auth-context';
import AppContext from '../../contexts/app-context';
import useAxios from '../../hooks/useAxios';
import { urls, app_paths, http_response_messages, toastify_status } from '../config/variables';
import classes from './NewUserForm.module.css';
import { Navigate } from 'react-router-dom';
import NewPatientSchema from '../schema/NewPatient.schema';
import {useTranslation} from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress';
import Toastify from '../ui/Toastify';
import { validateUserResponse, validateInsuranceAndFacilitiesList } from '../../Utils/validateHttpResponse';

function NewUserForm() {
  const authCtx = useContext(AuthContext);
  const appCtx = useContext(AppContext);
  const token = authCtx.token;
  const { operation } = useAxios();
  const navigate = useNavigate();
  const {t} = useTranslation('newPatient')
  //const direction = appCtx.direction

  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const nameInputRef = useRef();
  const phoneInputRef = useRef();
  const birthdayInputRef = useRef();
  const genderInputRef = useRef();
  const primaryInsuranceInputRef = useRef();
  const patient_primary_insurance_idInputRef = useRef();
  const secondaryInsuranceInputRef = useRef();
  const patient_secondary_insurance_idInputRef = useRef();
  const patient_primary_insurance_expirationInputRef = useRef();
  const patient_secondary_insurance_expirationInputRef = useRef();

  const eidInputRef = useRef();
  const physical_addressRef = useRef();
  const employerRef = useRef();
  const nationalityRef = useRef();

  const [userResponse, setUserResponse] = useState(undefined);
  const [userError, setUserError] = useState(undefined);
  const [validationErrors, setValidationErrors] = useState({});
  const [insuranceListResponse, setInsuranceListResponse] = useState([]);
  const [insuranceListError, setInsuranceListError] = useState([]);
  const [loadingAddPatient, setLoadingAddPatient] = useState(false);

  // get current date function
  const todayDate = () => {
  let today = new Date()
  let year = today.getFullYear() + 1;
  let month = String(today.getMonth() + 1).padStart(2, '0')
  let day = String(today.getDate()).padStart(2, '0')
  let currentDate = year + '-' + month + '-' + day

  return currentDate
  }

  const enteredEmail = emailInputRef.current?.value;
  const enteredPassword = passwordInputRef.current?.value;
  const enteredName = nameInputRef.current?.value;
  const enteredBirthday = birthdayInputRef.current?.value;
  const enteredPhone = phoneInputRef.current?.value;
  const enteredGender = genderInputRef.current?.value;
  const enteredPrimaryInsurance = primaryInsuranceInputRef.current?.value;
  const enteredPatientPrimaryInsID = patient_primary_insurance_idInputRef.current?.value;
  const enteredSecondaryInsurance = secondaryInsuranceInputRef.current?.value;
  const enteredPatientSecondaryInsID = patient_secondary_insurance_idInputRef.current?.value;
  const enteredPatientPrimaryInsExp = patient_primary_insurance_expirationInputRef.current?.value;
  const enteredPatientSecondaryInsExp = patient_secondary_insurance_expirationInputRef.current?.value;
  const enteredEid = eidInputRef.current?.value;
  const enteredphysical_address = physical_addressRef.current?.value;
  const enteredemployer = employerRef.current?.value;
  const enterednationality = nationalityRef.current?.value;

  const isDisabled = //disabled submit button
    !enteredEmail ||
    !enteredPassword ||
    !enteredName ||
    !enteredBirthday ||
    !enteredPhone ||
    !enteredGender ||
    !enteredPrimaryInsurance ||
    !enteredSecondaryInsurance ||
    !enteredEid ||
    !enteredphysical_address ||
    !enteredemployer ||
    !enterednationality ||
    Object.keys(validationErrors).length > 0;

    const disabledPrimaryInsurance =
    !enteredPatientPrimaryInsID ||
    !enteredPatientPrimaryInsExp

    const disabledSecondaryInsurance =
    !enteredPatientSecondaryInsID ||
    !enteredPatientSecondaryInsExp

  const validateField = (fieldId, value, otherValue) => {
    const { error } = NewPatientSchema.validate({ [fieldId]: value }, { abortEarly: false, context: {otherValue} });
    const errors = {};
    if (error) {
      for (const detail of error.details) {
        errors[detail.context.key] = detail.message;
      }
    }
    setValidationErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors, [fieldId]: errors };
      if (Object.keys(updatedErrors[fieldId]).length === 0) {
        delete updatedErrors[fieldId]; // Remove the fieldId if there are no errors
      }
      return updatedErrors;
    });
  };

  /* If user created successfully, add the user to the app context */
  useEffect(() => {
    if (!!userResponse) {
      appCtx.addSelectedUser(userResponse);
      appCtx.handleReFetchUsersList()
    }
  }, [userResponse, userError, appCtx]);

  /* Get the list of insurance in the system */
  useEffect(() => {
    operation(
      {
        method: 'GET',
        url: urls.LIST_INSURANCES,
        headers: { Authorization: `token ${token}` },
        data: {},
      },
      handleSetInsuranceListResponse,
      setInsuranceListError
    );
    // need operation and token
    // eslint-disable-next-line
  }, []);

  const handleSetInsuranceListResponse = (insuranceList) => {
    if(validateInsuranceAndFacilitiesList(insuranceList)) {
      setInsuranceListResponse(insuranceList)
    } else {
      console.log('invalid insurance list response')
      Toastify({message: 'invalid insurance list response', state: toastify_status.ERROR})
    }
  }

  /* InsuranceListError should be handled properly later  */
  useEffect(() => {
    if (typeof insuranceListError !== 'undefined') {
      console.log (insuranceListError)
    }
  }, [insuranceListError]);
  

  const submitHandler = (event) => {
    setLoadingAddPatient(true)
    event.preventDefault();
    const hasErrors = Object.values(validationErrors).some((fieldErrors) => Object.keys(fieldErrors).length > 0);
    if (hasErrors) {
      console.log(hasErrors);
      return;
    }
    const user = {
      email: enteredEmail,
      password: enteredPassword,
      name: enteredName,
      birthdate: enteredBirthday,
      gender: enteredGender,
      phone_number: enteredPhone,
      eid: enteredEid,
      physical_address: enteredphysical_address,
      employer: enteredemployer,
      nationality: enterednationality,
    };
    operation(
      {
        method: 'POST',
        url: urls.CREATE_A_PATIENT,
        headers: { Authorization: `token ${token}` },
        data: {
          user: user,
          primary_insurance:enteredPrimaryInsurance,
          patient_primary_insurance_id: enteredPatientPrimaryInsID,
          secondary_insurance:enteredSecondaryInsurance,
          patient_secondary_insurance_id: enteredPatientSecondaryInsID,
          patient_primary_insurance_expiration: enteredPatientPrimaryInsExp,
          patient_secondary_insurance_expiration: enteredPatientSecondaryInsExp,
          extrainfo: ""
        },
      },
      handleUserResponse,
      setUserError
    );
  };

  const handleUserResponse = (userResponst) => {
    setLoadingAddPatient(false)

    if(validateUserResponse(userResponst)) {
      setUserResponse(userResponst)
      Toastify({message: http_response_messages.CREATE_PATIENT, state: toastify_status.SUCCESS})
      setUserError(undefined)
    } else {
      Toastify({message: 'invalid user response', state: toastify_status.ERROR})
      console.log('invalid user response')
    }
  }

    /* If user is not created successfully, show the error message */
  useEffect(() => {
    if (userError) {
      if (userError.response.data) {
        const errors = userError.response.data.errors
        errors.map((error) => (
          Toastify({ message: error.detail, state: toastify_status.ERROR })
        ))
      } else {
        Toastify({ message: http_response_messages.ERROR, state: toastify_status.ERROR })
      }
    }
    setLoadingAddPatient(false)
  }, [userError]);

  const handleFormReset = (event) => {
    event.preventDefault();

    setValidationErrors({});
    //To be cleared when defaultValues are removed
    emailInputRef.current.value = '';
    passwordInputRef.current.value = '';
    nameInputRef.current.value = '';
    birthdayInputRef.current.value = '';
    genderInputRef.current.value = '';
    phoneInputRef.current.value = '';
    primaryInsuranceInputRef.current.value = '';
    patient_primary_insurance_idInputRef.current.value = '';
    secondaryInsuranceInputRef.current.value = '';
    patient_secondary_insurance_idInputRef.current.value = '';
    patient_primary_insurance_expirationInputRef.current.value = '';
    patient_secondary_insurance_expirationInputRef.current.value = '';
    eidInputRef.current.value = '';
    physical_addressRef.current.value = '';
    employerRef.current.value = '';
    nationalityRef.current.value = '';
  };

const goSomeWhere = (userResponse) => {
  if (appCtx.userRole === "doctor" ) {
    console.log("Role: Doctor, navigate to new encounter")
    // navigate("/");
    //navigate(0);
    return  <Navigate to={app_paths.NEW_ENCOUNTER.replace(':patientID', userResponse.id)} replace={true} />
  }
  else if (appCtx.userRole === "insurance_admin" ) {
    console.log("Role: Insurance, navigate to home page")
    navigate("/");
    navigate(0);
    return
  }
}

// remove errors and set expire to current date in case of out of pocket primary insuranse

useEffect(() => {
  if (enteredPrimaryInsurance === '1') {
    setValidationErrors(prevErrors => {
      const { patient_primary_insurance_id, patient_primary_insurance_expiration, ...rest } = prevErrors;
      return rest;
    });
    patient_primary_insurance_idInputRef.current.value = ''
    patient_primary_insurance_expirationInputRef.current.value = todayDate()
  } else {
    patient_primary_insurance_expirationInputRef.current.value = ''
  }
}, [enteredPrimaryInsurance]);

// remove errors and set expire to current date in case of out of pocket secondary insuranse

useEffect(() => {
  if (enteredSecondaryInsurance === '1') {
    setValidationErrors(prevErrors => {
      const { patient_secondary_insurance_id, patient_secondary_insurance_expiration, ...rest } = prevErrors;
      return rest;
    });
    patient_secondary_insurance_idInputRef.current.value = ''
    patient_secondary_insurance_expirationInputRef.current.value = todayDate()
  } else {
    patient_secondary_insurance_expirationInputRef.current.value = ''
  }
}, [enteredSecondaryInsurance])


  return (
    <div className={classes.container}>
      <form onReset={handleFormReset} onSubmit={submitHandler}>
        <div className={classes.form_container}>
          <div className={classes.control}>
            <label htmlFor="email">{t('UserEmail')}</label>
            <input
              type="email"
              id="email"
              placeholder="example@example.com"
              ref={emailInputRef}
              onChange={() => validateField('email', emailInputRef.current.value) }
            />
            {validationErrors.email && <p className={classes.helperText}>{validationErrors.email.email}</p>}
          </div>

          <div className={classes.control}>
            <label htmlFor="password">{t('UserPassword')}</label>
            <input
              type="password"
              id="password"
              placeholder="********"
              ref={passwordInputRef}
              onChange={() => validateField('password', passwordInputRef.current.value)}
            />
            {validationErrors.password && <p className={classes.helperText}>{validationErrors.password.password}</p>}
          </div>

          <div className={classes.control}>
            <label htmlFor="name">{t('Name')}</label>
            <input
              type="text"
              id="name"
              placeholder="John Doe"
              ref={nameInputRef}
              onChange={() => validateField('name', nameInputRef.current.value)}
            />
            {validationErrors.name && <p className={classes.helperText}>{validationErrors.name.name}</p>}
          </div>

          <div className={classes.control}>
            <label htmlFor="phone_number">{t('Phone')}</label>
            <input
              type="text"
              id="phone_number"
              placeholder="+1234567890"
              ref={phoneInputRef}
              onChange={() => validateField('phone_number', phoneInputRef.current.value)}
            />
            {validationErrors.phone_number && <p className={classes.helperText}>{validationErrors.phone_number.phone_number}</p>}
          </div>

          <div className={classes.control}>
            <label htmlFor="birthdate">{t('Birthdate')}</label>
            <input
              type="date"
              id="birthdate"
              placeholder="YYYY-MM-DD (e.g., 1990-01-01)"
              ref={birthdayInputRef}
              onChange={() => validateField('birthdate', birthdayInputRef.current.value)}
            />
            {validationErrors.birthdate && <p className={classes.helperText}>{validationErrors.birthdate.birthdate}</p>}
          </div>

          <div className={classes.control}>
            <label htmlFor="gender">{t('Gender')}</label>
            <select
              id="gender"
              ref={genderInputRef}
              className={enteredGender ? '' : classes.select_placeholder}
              onChange={() => validateField('gender', genderInputRef.current.value)}
            >
              <option value="" hidden>
              {t('MaleOrFemale')}
              </option>
              <option value="M" className={classes.select_options}>
              {t('Male')}
              </option>
              <option value="F" className={classes.select_options}>
              {t('Female')}
              </option>
            </select>
            {validationErrors.gender && <p className={classes.helperText}>{validationErrors.gender.gender}</p>}
          </div>

          <div className={classes.control}>
            <label htmlFor="primary_insurance">{t('PrimaryInsurance')}</label>
            <select
              id="primary_insurance"
              ref={primaryInsuranceInputRef}
              className={enteredPrimaryInsurance ? '' : classes.select_placeholder}
              onChange={() => {
                validateField('secondary_insurance', secondaryInsuranceInputRef.current.value, primaryInsuranceInputRef.current.value)
                validateField('primary_insurance', primaryInsuranceInputRef.current.value, secondaryInsuranceInputRef.current.value)
              }}
            >
              <option hidden>{t('SelectInsurance')}</option>
              {insuranceListResponse.map ( (insuranceListOption) => <option key={insuranceListOption.id} value={insuranceListOption.id}>{insuranceListOption.name}</option> ) }
            </select>
            {validationErrors.primary_insurance && <p className={classes.helperText}>{validationErrors.primary_insurance.primary_insurance}</p>}
          </div>

          <div className={classes.control}>
            <label htmlFor="patient_primary_insurance_id">{t('PrimaryInsuranceID')}</label>
            <input
              type="text"
              id="patient_primary_insurance_id"
              placeholder={enteredPrimaryInsurance === '1' ? 'No Need For ID (Out of Pocket)' : 'primary insrance id'}
              ref={patient_primary_insurance_idInputRef}
              disabled={enteredPrimaryInsurance === '1'}
              onChange={() => validateField('patient_primary_insurance_id', patient_primary_insurance_idInputRef.current.value)}
            />
            {validationErrors.patient_primary_insurance_id && <p className={classes.helperText}>{validationErrors.patient_primary_insurance_id.patient_primary_insurance_id}</p>}
          </div>

          <div className={classes.control}>
            <label htmlFor="secondary_insurance">{t('SecondaryInsurance')}</label>
            <select
              id="secondary_insurance"
              ref={secondaryInsuranceInputRef}
              className={enteredSecondaryInsurance ? '' : classes.select_placeholder}
              onChange={() => {
                validateField('secondary_insurance', secondaryInsuranceInputRef.current.value, primaryInsuranceInputRef.current.value)
                validateField('primary_insurance', primaryInsuranceInputRef.current.value, secondaryInsuranceInputRef.current.value)
              }}
            >
              <option hidden>{t('SelectInsurance')}</option>
              {insuranceListResponse.map ( (insuranceListOption) => <option key={insuranceListOption.id} value={insuranceListOption.id}>{insuranceListOption.name}</option> ) }
            </select>
            {validationErrors.secondary_insurance && <p className={classes.helperText}>{validationErrors.secondary_insurance.secondary_insurance}</p>}
          </div>

          <div className={classes.control}>
            <label htmlFor="patient_secondary_insurance_id">{t('SecondaryInsuranceID')}</label>
            <input
              type="text"
              id="patient_secondary_insurance_id"
              placeholder={enteredSecondaryInsurance === '1' ? 'No Need For ID (Out of Pocket)' : 'secondary insrance id'}
              ref={patient_secondary_insurance_idInputRef}
              disabled={enteredSecondaryInsurance === '1'}
              onChange={() => validateField('patient_secondary_insurance_id', patient_secondary_insurance_idInputRef.current.value)}
            />
            {validationErrors.patient_secondary_insurance_id && <p className={classes.helperText}>{validationErrors.patient_secondary_insurance_id.patient_secondary_insurance_id}</p>}
          </div>

          <div className={classes.control}>
            <label htmlFor="patient_primary_insurance_expiration">{t('PrimaryInsuranceExpiration')}</label>
            <input
              type="date"
              id="patient_primary_insurance_expiration"
              placeholder="YYYY-MM-DD (e.g., 2024-01-01)"
              ref={patient_primary_insurance_expirationInputRef}
              disabled={enteredPrimaryInsurance === '1'}
              onChange={() => validateField('patient_primary_insurance_expiration', patient_primary_insurance_expirationInputRef.current.value)}
            />
            {validationErrors.patient_primary_insurance_expiration && <p className={classes.helperText}>{validationErrors.patient_primary_insurance_expiration.patient_primary_insurance_expiration}</p>}
          </div>

          <div className={classes.control}>
            <label htmlFor="patient_secondary_insurance_expiration">{t('SecondaryInsuranceExpiration')}</label>
            <input
              type="date"
              id="patient_secondary_insurance_expiration"
              placeholder="YYYY-MM-DD (e.g., 2024-01-01)"
              ref={patient_secondary_insurance_expirationInputRef}
              disabled={enteredSecondaryInsurance === '1'}
              onChange={() => validateField('patient_secondary_insurance_expiration', patient_secondary_insurance_expirationInputRef.current.value)}
            />
            {validationErrors.patient_secondary_insurance_expiration && <p className={classes.helperText}>{validationErrors.patient_secondary_insurance_expiration.patient_secondary_insurance_expiration}</p>}
          </div>


          <div className={classes.control}>
            <label htmlFor="eid">{t('GovernmentID')}</label>
            <input
              type="text"
              id="eid"
              placeholder="A12345678"
              ref={eidInputRef}
              onChange={() => validateField('eid', eidInputRef.current.value)}
            />
            {validationErrors.eid && <p className={classes.helperText}>{validationErrors.eid.eid}</p>}
          </div>

          <div className={classes.control}>
            <label htmlFor="physical_address">{t('PhysicalAddress')}</label>
            <input
              type="text"
              id="physical_address"
              placeholder="123 Main Street, City, State"
              ref={physical_addressRef}
              onChange={() => validateField('physical_address', physical_addressRef.current.value)}
            />
            {validationErrors.physical_address && (
              <p className={classes.helperText}>{validationErrors.physical_address.physical_address}</p>
            )}
          </div>

          <div className={classes.control}>
            <label htmlFor="employer">{t('Employer')}</label>
            <input
              type="text"
              id="employer"
              placeholder="ABC Company"
              ref={employerRef}
              onChange={() => validateField('employer', employerRef.current.value)}
            />
            {validationErrors.employer && <p className={classes.helperText}>{validationErrors.employer.employer}</p>}
          </div>

          <div className={classes.control}>
            <label htmlFor="nationality">{t('Nationality')}</label>
            <input
              type="text"
              id="nationality"
              placeholder="United States"
              ref={nationalityRef}
              onChange={() => validateField('nationality', nationalityRef.current.value)}
            />
            {validationErrors.nationality && <p className={classes.helperText}>{validationErrors.nationality.nationality}</p>}
          </div>
        </div>

        <div className={classes.actions_container}>
          <div className={[classes.actions, classes.actions_add].join(' ')}>
            <button type="submit" disabled={isDisabled || (enteredPrimaryInsurance === 1 && !disabledPrimaryInsurance) || (enteredSecondaryInsurance === 1 && !disabledSecondaryInsurance)}>
              {t('AddPatient')}
            </button>
          </div>
          {loadingAddPatient && (
            <div className={[classes.actions]}>
            <CircularProgress/>
          </div>
          )}
          <div className={[classes.actions, classes.actions_clear].join(' ')}>
            <button onClick={handleFormReset}>{t('Clear')}</button>
          </div>
        </div>
        {userResponse && !userError && goSomeWhere(userResponse)}
      </form>
    </div>
  );
}

export default NewUserForm;
