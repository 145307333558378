import React, {useContext} from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AppContext from '../../contexts/app-context';

export default function SimpleSnackbar() {

  const appCtx = useContext(AppContext)
  const openSnackBar = appCtx.openSnackBar
  const title = appCtx.snackBarTitle

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    appCtx.handleOpenSnackBar(title)
  };

  return (
   
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {title}
        </Alert>
      </Snackbar>
   
  );
}
