import React, { useState } from 'react';
import './IncrementInput.css';
// this component for changing claimed quantity by insurance 

const IncrementInput = ({ initialValue, handleChangeApprovedQuantity, drug }) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        const newValue = e.target.value.replace(/\D/, '');
        if (newValue >= 0 && newValue <= drug.claimed_quantity) {
            setValue(newValue);
            handleChangeApprovedQuantity(newValue, drug);
        }
    };

    const handleIncrement = () => {
        if (value < drug.claimed_quantity) {
            const newValue = parseInt(value) + 1;
            setValue(newValue);
            handleChangeApprovedQuantity(newValue, drug);
        }

        if(!value) {
            setValue(1);
            handleChangeApprovedQuantity(1, drug);
        }
    };

    const handleDecrement = () => {
        if (value > 0) {
            const newValue = parseInt(value) - 1;
            setValue(newValue);
            handleChangeApprovedQuantity(newValue, drug);
        }
    };

    return (
        <span className="increment-input-container">
            <button className="decrement-button" onClick={handleDecrement}>-</button>
            <input className='increment-input' type='text' value={value} size={value.length || 1} onChange={handleChange} />
            <button className="increment-button" onClick={handleIncrement}>+</button>
        </span>
    );
};

export default IncrementInput;

