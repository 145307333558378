import AuthForm from '../../components/Auth/AuthForm';
import ImageSlider from '../../components/imageSlider/ImageSlider';
import classes from './AuthPage.module.css'
import LanguageSwitcher from '../../components/ui/LanguageSwitcher/LanguageSwitcher';

const AuthPage = () => {
  return (
    <div className={classes.container}>
      <div className={classes.cover}>
        <div className={classes.header}>
          <div className={classes.image}>
            <img src="/images/logo/black/full-black.png" alt="logo" className={classes.logo} />
          </div>
          <div className={classes.langIcon}>
            <LanguageSwitcher customStyle={{}} />
          </div>
        </div>
        <div className={classes.form}>
          <AuthForm />
        </div>
      </div>
      <div className={classes.slider}>

      <ImageSlider />
      </div>
    </div>
    );
};

export default AuthPage;
