import BarsDataset from "./BarChart";
import { useContext, useState, useEffect } from 'react';
import AppContext from "../../../contexts/app-context";
import AuthContext from "../../../contexts/auth-context";
import useAxios from "../../../hooks/useAxios";
import { urls, toastify_status } from '../../config/variables';
import { validateFrequencyDoctorExpenditure } from "../../../Utils/validateHttpResponse";
import Toastify from "../../ui/Toastify";

const DoctorExpenditureBarChart = () => {
    const { operation } = useAxios();
    const authCtx = useContext(AuthContext);
    const appCtx = useContext(AppContext)
    const token = authCtx.token;

    const [data, setData] = useState('')
    const FrequencyDate = appCtx.searchByFrequencyDateQuery

    const updatePricesChartData = () => {
        operation(
            {
                method: 'GET',
                url: urls.DOCTOR_PRICES + `?frequency_date=${FrequencyDate}`,
                headers: { Authorization: `token ${token}` },
                data: {},
            },
            handleSetData,
            (err) => console.log(err)
        );
    }

    const handleSetData = (data) => {
        if(validateFrequencyDoctorExpenditure(data)) {
            setData(data)
        } else {
            Toastify({message: 'invalid Frequency Doctor Expenditure response data', state: toastify_status.ERROR})
            console.log('invalid Frequency Doctor Expenditure response data')
        }
    }

    useEffect(() => {
        updatePricesChartData()
        // eslint-disable-next-line
    }, [FrequencyDate])

return <BarsDataset  data={data} title='Doctor Expenditure' notFoundDataMessage='No Doctor Expenditure'/>

}

export default DoctorExpenditureBarChart
