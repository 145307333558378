const NewEncounterPageStyle = () => {

  return{
    container: {
      padding: '24px 32px 32px 32px',
      border: '1px solid #D9D9D9',
      borderRadius: '10px',
      marginBottom: '40px'
    },
    alert_box:{ 
      backgroundColor: '#f8d7da',
      color: '#721c24'
     },
     alert_title:{
        fontWeight: 600
     }
    
  };

};

export default NewEncounterPageStyle;