import { useTheme } from '@emotion/react';

const NavigationTabStyle = () => {
  const theme = useTheme();

  const navLinkStyles = ({isActive}) => {
    return {
      textDecoration: 'none',
      textTransform: 'capitalize',
      padding: '32px',
      backgroundColor: isActive? theme.palette.primary['30'] : '#D9D9D980',
      display: 'flex' ,
      justifyContent: 'start',
      width: '100%',
      gap: '16px',
      color: '#000',

    }
  }

  return{
    container:{ 
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '24px'
  },
  nav_link_style: navLinkStyles,
  tab_title: {
    color: '#000',
    fontSize: '20px'
  },
  icons: {
    width: '32px',
    height: '32px',
  },
};

};

export default NavigationTabStyle;