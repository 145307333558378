const EncounterCardStyle = () => {
  return {
    accept_accordion: {
      color: '#0f2e11',
      background: '#b5dbb5',
      marginTop: '16px',
      '&: hover': {
        background: '#aad9aa',
      },
    },
    reject_accordion: {
      background: '#f5cece',
      color: '#5f2120',
      marginTop: '16px',
      '&: hover': {
        background: '#ebbebe',
      },
    },
    partially_accept_accordion: {
      background: '#fdd8ab',
      color: '#ae6200',
      marginTop: '16px',
      '&: hover': {
        background: '#fdce95',
      },
    },
    pending_approval_accordion: {
      background: '#d4d4d4',
      color: '#333',
      marginTop: '16px',
      '&:hover': {
        background: '#CACACA',
  },
    },
    accordion_header: {
      flexBasis: '30%',
      fontSize: '18px',
      fontWeight: '500',
      textTransform: 'capitalize',
      color: '#000000c7'
    },
    info: {
      marginBottom: '10px',
    },
    info_text: {
      fontWeight: '500',
      marginBottom: '5px',
      textTransform: 'capitalize',
      color: '#000000c7'
    },
    pointer_info_text: {
      cursor: 'pointer',
      display: 'inline-block',
      color: '#5272F2',
      borderBottom: '1px solid #5272F2',
      marginLeft: '10px'
    },
    encounter_provider_date: {
      flexBasis: '22%',
      fontWeight: '500',
      textTransform: 'capitalize',
    },
    encounter_title: {
      fontWeight: '500',
      color: '#000000c7'
    },
    encounter_info: {
      marginTop: '4px',
      paddingLeft: '8px',
      color: '#000000c7',
    },
    encounter_info_error: {
      marginTop: '4px',
      paddingLeft: '8px',
      color: '#721c24',
    },
    encounter_info_error_rejected: {
      marginTop: '4px',
      paddingLeft: '8px',
      color: '#ff0018',
    },
    dot_icon: {
      fontSize: '14px',
      marginRight: '4px',
    },
    drug_dot_icon: {
      fontSize: '8px',
      marginRight: '4px',
    },
    encounter_message: {
      paddingLeft: '24px',
    },
    encounter_point: {
      display: 'flex',
      alignItems: 'center',
    },
    drug_messages_point: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '30px',
    },
    circular: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    accepted_reload: {
      color: '#006400'
    },
    partially_accept_reload: {
      color: '#333333'
    },
    rejected_reload: {
      color: '#800000'
    },
    error_msg: {
      color: 'red'
    },
    claimed_quantity_box: {
      marginLeft: '22px',
      display: 'flex'
    },
    claimed_quantity: {
      marginRight: '10px'
    },
    claimed_quantity_value: {
      fontWeight: '500'
    },
    select_quantity: {
      marginLeft: '5px',
      padding: '0 2px',
      textAlign: 'center',
      background: 'transparent',
      border: '1px solid black',
      backgroundColor: 'black',
      color: 'white',
    },
    reloadBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '10px'
    },
    reloadBtn: {
      backgroundColor: 'red',
      color: 'white',
      padding: '4px 12px',
      margin: '0 10px',
      borderRadius: '4px',
      cursor: 'pointer',
      letterSpacing: '1.5px',
      display: 'flex'
  },
    reloadIcon: {
      fontSize: '20px',
      marginRight: '5px'
    },
    btn_accepted_reload: {
      backgroundColor: '#074173'
    },
    btn_partially_accept_reload: {
      backgroundColor: '#333333'
    },
    insurance_save_button: {
      float: 'left',
      fontSize: '10px',
      fontWeight: '600',
      backgroundColor: 'grey',
      margin: '10px 7px',
       maxWidth: '50px'
    }
  };
};

export default EncounterCardStyle;
