import Box from '@mui/material/Box';
import ChangePasswordForma from '../../components/Auth/ChangePasswordForma';
import { useState, useContext, useEffect } from 'react';
import useAxios from '../../hooks/useAxios';
import AuthContext from '../../contexts/auth-context';
import { urls, toastify_status } from '../../components/config/variables';
import UploadFileForm from '../../components/files/UploadFileForm';
import AppContext from '../../contexts/app-context';
import { validateInsuranceAndFacilitiesList } from '../../Utils/validateHttpResponse';
import Toastify from '../../components/ui/Toastify';
import classes from './Setting.module.css'

const Setting = () => {
    const { operation } = useAxios();
    const authCtx = useContext(AuthContext);
    const appCtx = useContext(AppContext)
    const userRole = appCtx.userRole

    const [facilities, setFacilities] = useState(undefined);

    useEffect(() => {
        if (userRole === 'insurance_admin' && typeof facilities === 'undefined') {
            operation(
                {
                    method: 'GET',
                    url: urls.LIST_FACILITIES,
                    headers: { Authorization: `token ${authCtx.token}` },
                    data: {},
                },
                handleSetFacilities,
                ((err) => console.log(err))
            );
        }
        // eslint-disable-next-line
    }, [JSON.stringify(facilities)]);

    const handleSetFacilities = (facilitiesList) => {
        if(validateInsuranceAndFacilitiesList(facilitiesList)) {
            setFacilities(facilitiesList)
        } else {
            console.log('invalid facilities list response')
            Toastify({message: 'invalid facilities list response', state: toastify_status.ERROR})
        }
    }

    return (
        <Box className={classes.container}>
            {userRole === 'insurance_admin' && <Box className={classes.file_form_box}>
                <UploadFileForm facilities={facilities} />
            </Box>}

            <Box className={classes.change_password_box}>
                <ChangePasswordForma />
            </Box>
        </Box>
    );
};

export default Setting;
